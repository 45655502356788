import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pd-bulk-message-email-chips-field',
  templateUrl: './bulk-message-email-chips-field.component.html',
  styleUrls: ['./bulk-message-email-chips-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageEmailChipsFieldComponent {
  @Input() isTemplatePreview = false;
  emailForm: FormGroup;
  emails: string[] = [];

  constructor(
    private cdRef: ChangeDetectorRef, 
    private fb: FormBuilder) {
    this.initializeForm();
  }

  private initializeForm() {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  addEmail() {
    if (this.emailForm.invalid) {
      return;
    }

    const email = this.emailForm.value.email.trim();
    if (email && !this.emails.includes(email)) {
      this.emails.push(email);
      this.emailForm.reset();
    }
    this.cdRef.detectChanges();
  }

  removeEmail(index: number) {
    this.emails.splice(index, 1);
    this.cdRef.detectChanges();
  }

  setEmails(emails: string[]) {
    this.emails = emails;
    this.cdRef.detectChanges();
  }

  getEmails(): string[] {
    return this.emails;
  }
}