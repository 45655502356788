<modal id="stripe-payment-form" #modal title="Stripe" primaryBtnTextKey="Close" [isInformational]="true"
    [displayLaunchButton]="false" (secondaryClick)="onClose()" (onCompleteAction)="onClose()"
    [loadingIndicator]="isSpinner" [loadingIndicatorAbsolute]="true" (primaryClick)="onClose()">
    <form (ngSubmit)="handleSubmit($event)">
        <div id="pd-stripe-payment-element">
        </div>
        <button class="button button-pay" id="submit" type="submit" [disabled]="isSpinner">
            @if (!isSpinner) {
            <span id="button-text">Pay now</span>
            }
        </button>
        @if (paymentMessage) {
        <div class="payment-message">
            <fa-icon *ngIf="isError" [icon]="faCircleExclamation" class="fa-circle-exclamation-icon"></fa-icon>
            <span [ngStyle]="{'color': isError ? 'red': 'blue'}">
                {{paymentMessage}}
            </span>
        </div>
        }
    </form>
</modal>