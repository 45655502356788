import { MessageTemplateType } from "../../enums/MessageTemplateType";
import { ObjectUtil } from "../../utils/object.util";
import { MessageTemplateVariableDescriptionViewModel } from "./MessageTemplateVariableDescriptionViewModel";

export class MessageTemplateViewModel {
    public id: number;
    public displayName : string;
    public from : string;
    public fromName : string;
    public emailsCc : string;
    public emailsBcc : string;
    public subject : string;
    public body : string;
    public variableDescriptions : MessageTemplateVariableDescriptionViewModel[];
    public messageTemplateType: MessageTemplateType;
    public isNeedUnsubscribeHtml: boolean;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}