<div>
    <div>
        <div class="settings-table">
            <div class="settings-table__label">
                {{title}}
            </div>
            <div class="settings-table__header row">
                <div class="col-1 cell">ID of setting</div>
                <div class="col cell">Statuses of bulk message requests</div>
                <div class="col cell">Settings Info</div>
                <div class="col cell">Request Info</div>
                <div class="col cell">Location</div>
                <div class="col-1 cell">Creation Date</div>
                <div class="col-2 cell">Actions</div>
            </div>
            <div class="settings-table__content">
                <ng-container *ngFor="let setting of settings">
                    <pd-bulk-message-table-row [setting]="setting" [isMain]="isMain" [isArchive]="isArchive"
                        [isSelectedItem]="isSelectedItem" (onRequestSelectChange)="onRequestSelectChange($event)"
                        (onShowTemplate)="onShowTemplate($event)" (onRemove)="this.onRemove.emit($event)"
                        (onArchive)="onArchive.emit($event)"
                        (onConfigureMessage)="this.onConfigureMessage.emit($event)">
                    </pd-bulk-message-table-row>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div #fullscreenView class="fullscreen">
    <button class="fullscreen-close-btn waves-light btn-danger btn" (click)="closeTemplateView()">
        <i class="material-icons">close</i>
    </button>
    <div class="row">
        <div class="col-10 fullscreen-content__wrapper">
            <pd-bulk-message-email #templateView [isTemplatePreview]="true"></pd-bulk-message-email>
        </div>
    </div>
</div>