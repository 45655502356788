import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';

@Component({
  selector: 'pd-bulk-message-status-filter',
  templateUrl: './bulk-message-status-filter.component.html',
  styleUrls: ['./bulk-message-status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageStatusFilterComponent implements OnInit {
  @Input() messageTemplateType: MessageTemplateType;
  @Output() public onFilterChange = new EventEmitter<any>();

  sendToReceived?: boolean = null;
  sendToRead?: boolean = null;
  sendToReplied?: boolean = null;

  MessageTemplateType = MessageTemplateType;

  ngOnInit() {
    this.onFilterChange.emit(this.getSelectedStatuses());
  }

  onReceivedStatusChange(value: string) {
    if (value === 'received') {
      this.sendToReceived = true;
    } else if (value === 'notReceived') {
      this.sendToReceived = false;
    } else if (value === 'both') {
      this.sendToReceived = null;
    }

    this.sendToRead = null;
    this.sendToReplied = null;

    this.onFilterChange.emit(this.getSelectedStatuses());
  }

  onReadStatusChange(value: 'sendToRead' | 'sendToNotRead' | 'both') {
    if (value === 'sendToRead') {
      this.sendToRead = true;
    } else if (value === 'sendToNotRead') {
      this.sendToRead = false;
    } else if (value === 'both') {
      this.sendToRead = null;
    }

    this.sendToReplied = null;

    this.onFilterChange.emit(this.getSelectedStatuses());
  }

  onReplyStatusChange(value: 'sendToReplied' | 'sendToNotReplied' | 'both') {
    if (value === 'sendToReplied') {
      this.sendToReplied = true;
    } else if (value === 'sendToNotReplied') {
      this.sendToReplied = false;
    } else if (value === 'both') {
      this.sendToReplied = null;
    }
    this.onFilterChange.emit(this.getSelectedStatuses());
  }

  public getSelectedStatuses() {
    const data = { sendToReceived: this.sendToReceived, sendToRead: this.sendToRead, sendToReplied: this.sendToReplied };
    return data;
  }
}