import { LinkTypes } from "../enums/LinkTypes";

export class ThirdPartyLink {
    public id: number = 0;
    public dealId: number = 0;
    public link: string = ""; 
    public linkType: LinkTypes = LinkTypes.Instagram;
    public order: number = 0;
    public created: Date;
    public createdBy: number = 0;
    public modified: Date;
    public modifiedBy: number = 0;
}