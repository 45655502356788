import { Component, OnInit, Input, ViewChildren, QueryList, ViewChild, Output, EventEmitter } from '@angular/core';
import { FieldIntlTelInputComponent } from '../field-landing/field-intl-tel-input/field-intl-tel-input.component';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'pd-field-intl-tel',
  templateUrl: './field-intl-tel.component.html',
  styleUrls: ['./field-intl-tel.component.scss']
})
export class FieldIntlTelComponent implements OnInit {
  @ViewChildren('inputFields') inputFields: QueryList<FieldIntlTelInputComponent>;
  @Input() phones = [];
  @Input() isSinglePhone: boolean = false;
  @Input() isNeedSaveCancelBtn = true;
  @Output() phonesSave = new EventEmitter<Object>();
  @Output() phonesCancel = new EventEmitter<Object>();

  public isEdit = false;
  public prevPhones = [];
  public label: string;
  public phoneLabels = ['Work', 'Home', 'Mobile', 'Other'];
  public faDelete = faRemove;

  constructor() { }

  ngOnInit() {
    this.prevPhones = this.deepClone(this.phones);
    this.isEdit = true;
  }

  public isAllValidCheck() {
    return !(this.inputFields && this.inputFields.some((input) => !input.checkIsValid()));
  }

  public onPhoneChange(phone, updatedPhoneModel) {
    phone.tempNewValue = updatedPhoneModel.phone;
  }

  public addOneMore() {
    this.phones.push({ value: '', label: 'Work' });
  }

  public deletePhone(index) {
    if (this.phones && this.phones.length > 0)
      this.phones.splice(index, 1)
  }

  public save() {
    if (this.isAllValidCheck()) {
      this.isEdit = false;
      let phones = this.getPhones();
      this.phonesSave.emit(phones);
    }
  }

  public getPhones() {
    this.phones.forEach((phone) => { if (phone.tempNewValue !== undefined) { phone.value = phone.tempNewValue } });
    this.prevPhones = this.deepClone(this.phones);
    return this.phones;
  }

  public cancel() {
    this.isEdit = false;
    this.phones = this.deepClone(this.prevPhones);
    this.phonesCancel.emit(this.phones);
  }

  private deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }
}
