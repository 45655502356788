import { Message } from "../models/chat-models/Message";
import { faPaperclip, faClock, faTimes, faCheck, faCheckDouble, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export class TwilioHelper {
  public static textForReactionMessage = '***Customer reacted with an emoji to the message***';

  public static faPaperclip = faPaperclip;

  public static isReactionMessage(message: Message) {
    return message.text === '' && message.isClientMessage && (!message.medias || message.medias.length === 0);
  }

  public static getMessageIcon(message: Message) {
    switch (message.status) {
      case 'queued':
        return faClock;
      case 'accepted':
        return faClock;
      case 'sent':
        return faPaperPlane;
      case 'delivered':
        return faCheck;
      case 'received':
        return faCheck;
      case 'read':
        return faCheckDouble;
      case 'failed':
        return faTimes;
      case 'undelivered':
        return faTimes;
      default:
        return '';
    }
  }
}
