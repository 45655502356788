import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Tab } from '../core/models/tab.model';
import { BulkMessageSettingUpdateDto } from '../core/models/dto/BulkMessageSettingUpdateDto';
import { BulkMessageSettingApiService } from '../core/services/bulk-message/bulk-message-setting-api.service';
import { BulkMessageFilterComponent } from './bulk-message-filter/bulk-message-filter.component';
import { BulkMessageSettingViewModel } from '../core/models/bulk-message/BulkMessageSettingsViewModel';

@Component({
  selector: 'pd-bulk-message-settings',
  templateUrl: './bulk-message-settings.component.html',
  styleUrls: ['./bulk-message-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageSettingsComponent implements OnInit {
  @ViewChild("filter") filter: BulkMessageFilterComponent;

  settings: Array<BulkMessageSettingViewModel> = [];
  spinner: boolean = false;
  deleteCollectionMessage = "Do you confirm deletion of this setting?";

  tabs: Tab[] = [
    new Tab({ isActive: true, name: "Bulk Message Rules", id: 1 }),
    new Tab({ isActive: false, name: "Archive", id: 2 })
  ];

  constructor(private cdRef: ChangeDetectorRef, private bulkMessageSettingService: BulkMessageSettingApiService) { }

  async ngOnInit() {
    await this.loadSettings();
  }

  async loadSettings(): Promise<void> {
    this.spinner = true;
    this.cdRef.detectChanges();
    this.settings = await this.bulkMessageSettingService.getAll();
    this.spinner = false;
    this.cdRef.detectChanges();
  }

  getNonArchivedSettings(): Array<BulkMessageSettingViewModel> {
    return this.settings.filter(setting => !setting.isArchived);
  }

  getArchivedSettings(): Array<BulkMessageSettingViewModel> {
    return this.settings.filter(setting => setting.isArchived);
  }

  async onAdd(): Promise<void> {
    this.spinner = true;
    this.cdRef.detectChanges();
    const data = this.createSettingToAdd();
    await this.bulkMessageSettingService.add(data);
    await this.loadSettings();
  }

  async onRemove(event: { settingId: number }): Promise<void> {
    if (confirm(this.deleteCollectionMessage)) {
      await this.bulkMessageSettingService.remove(event.settingId);
      await this.loadSettings();
    }
  }

  async onArchive(event: { settingId: number, value: boolean }): Promise<void> {
    await this.bulkMessageSettingService.archive(event.settingId, event.value);
    await this.loadSettings();
  }

  onTabClick(tab: Tab): void {
    this.tabs.forEach(t => t.isActive = false);
    tab.isActive = true;
  }

  createSettingToAdd(): BulkMessageSettingUpdateDto {
    const filterData = this.filter.getFilterData();
    return new BulkMessageSettingUpdateDto({
      clientRatings: filterData.clientRatings,
      pipelines: filterData.pipelines,
      ownerIds: filterData.ownerIds,
      locations: filterData.locations
    });
  }

  trackByFn(index: number, item: Tab): number {
    return item.id;
  }
}