import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpRestService } from '../http-client/http-rest-client.service';
import { BulkMessageJobModel } from '../../models/task-models/BulkMessageJobModel';
import { BulkMessageRequestReportViewModel } from '../../models/bulk-message/BulkMessageRequestReportViewModel';
import { BulkMessageRequestUpdateDto } from '../../models/dto/BulkMessageRequestUpdateDto';

@Injectable()
export class BulkMessageRequestApiService extends BaseService {
	constructor(private restService: HttpRestService) {
		super();
	}

	public async add(data: BulkMessageRequestUpdateDto): Promise<number> {
		const url = '/bulk-message-request/add';
		return await this.handleResponse(this.restService.post({ url, data }));
	}

	public async sendBulkMessage(data: BulkMessageJobModel): Promise<boolean> {
		const url = '/bulk-message-request/send-bulk-message';
		return await this.handleResponse(this.restService.post({ url, data }));
	}

	public async getRequestReport(requestId: number): Promise<BulkMessageRequestReportViewModel[]> {
		const url = '/bulk-message-request/get-request-report/' + requestId;
		return await this.handleResponse(this.restService.get({ url }));
	}

	public async getSimpleDealInfo(dealId: number): Promise<number> {
		const url = '/bulk-message-request/simple-deal-info/' + dealId;
		return await this.handleResponse(this.restService.get({ url }));
	}

	public async sendPreviewEmail(data): Promise<boolean> {
		const url = '/bulk-message-request/send-preview-email';
		return await this.handleResponse(this.restService.post({ url, data }));
	}
}
