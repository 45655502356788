<ng-container>
  <div class="row wrapper">
    <div class="col">

      @if(isTemplatePreview)
      {
      <div class="row custom-row">
        <div class="col custom-col">
          <button class="btn btn-success waves-light copy-template-btn" (click)="onCopy();">Copy Template
            <p class="tooltop" [ngStyle]="{'display': copied ? 'block': 'none'}">Copied!</p>
          </button>
        </div>
      </div>
      }

      <div class="row custom-row">
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Message From Name
          </div>
          <div class="variable_sample__value" [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
            <input type="text" [(ngModel)]="messageFromName" [disabled]="isTemplatePreview">
          </div>
        </div>
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Message From Email
          </div>
          <div class="variable_sample__value" [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
            <input type="text" [(ngModel)]="messageFromEmail" [disabled]="isTemplatePreview">
          </div>
        </div>
      </div>

      <div class="row custom-row">
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Emails Cc
          </div>
          <pd-bulk-message-email-chips-field #emailsCcField
            [isTemplatePreview]="isTemplatePreview"></pd-bulk-message-email-chips-field>
        </div>
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Emails Bcc
          </div>
          <pd-bulk-message-email-chips-field #emailsBccField
            [isTemplatePreview]="isTemplatePreview"></pd-bulk-message-email-chips-field>
        </div>
      </div>

      <div class="row custom-row">
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Title of email
          </div>
          <div class="variable_sample__value" [ngClass]="{'variable_sample__value_disabled': isTemplatePreview }">
            <input type="text" [(ngModel)]="emailSubject" (ngModelChange)="generateSubjectVariables()"
              [disabled]="isTemplatePreview">
          </div>
        </div>
      </div>

      <div class="row custom-row">
        @for(variable of subjectVariables; track variable) {
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Sample content for subject variable
            <span>&#123;</span><span>&#123;</span>{{variable.variableNumber}}<span>&#125;</span><span>&#125;</span>
          </div>
          <div class="variable_sample__value variable_sample__value_disabled">
            <input type="text" [(ngModel)]="variable.exampleData" disabled="true">
          </div>
          <div class="variable_sample__type">
            <mat-form-field class="full-width">
              <mat-label>Select Variable Type</mat-label>
              <mat-select [(ngModel)]="variable.templateVariableContentType" [disabled]="isTemplatePreview"
                (ngModelChange)="updateExampleData(variable)">
                @for (type of variableTypes; track type) {
                <mat-option [value]="type.id">
                  {{ type.name }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        }
      </div>

      <div class="row custom-row">
        <div class="col">
          @if(!isTemplatePreview)
          {
          <quill-editor class="full-width" [(ngModel)]="emailBody" [modules]="editorModules"
            (onContentChanged)="onContentChanged($event)"></quill-editor>
          }
          @if(isTemplatePreview)
          {
          <div class="quill-preview">
            <quill-view-html [content]="emailBody"></quill-view-html>
          </div>
          }
        </div>
      </div>

      <div class="row custom-row">
        @for(variable of variableDescriptions; track variable) {
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Sample content for body variable
            <span>&#123;</span><span>&#123;</span>{{variable.variableNumber}}<span>&#125;</span><span>&#125;</span>
          </div>
          <div class="variable_sample__value variable_sample__value_disabled">
            <input type="text" [(ngModel)]="variable.exampleData" disabled="true">
          </div>
          <div class="variable_sample__type">
            <mat-form-field class="full-width">
              <mat-label>Select Variable Type</mat-label>
              <mat-select [(ngModel)]="variable.templateVariableContentType" [disabled]="isTemplatePreview"
                (ngModelChange)="updateExampleData(variable);generateInlinePlaceholder()">
                @for (type of variableTypes; track type) {
                <mat-option [value]="type.id">
                  {{ type.name }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        }
      </div>

      <div class="row custom-row">
        <mat-checkbox [(ngModel)]="isNeedUnsubscribeHtml" [disabled]="isTemplatePreview">Add Unsubscribe
          Button</mat-checkbox>
      </div>

      @if(!isTemplatePreview)
      {
      <div class="row custom-row">
        <div class="col variable_sample__item">
          <div class="variable_sample__label">
            Check the email by sending the preview (just indicate the email and click on Send Preview
            button)
          </div>
          <div class="row">
            <div class="col">
              <div class="variable_sample__value">
                <input type="text" [(ngModel)]="previewEmailTo">
              </div>
            </div>
            <div class="col custom-col">
              <button class="btn btn-primary waves-light send-preview-btn" (click)="sendPreviewEmail()" [disabled]="!sendPreviewValidation()">Send
                Preview
                <p class="tooltop" [ngStyle]="{'display': sent ? 'block': 'none'}">Sent!</p>
                <p class="tooltop tooltop-error" [ngStyle]="{'display': errorSent ? 'block': 'none'}">
                  Error!</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      }

    </div>
  </div>
</ng-container>