<div style="height: 100%; width: 100%;">
    <div>
        <ng-container *ngIf="!isEdit; else editInProgress">
            <div *ngIf="thirdPartyLinks.length > 0" class="col-10 notes">
                <div class="link-field">
                    <div *ngFor="let thirdPartyLink of thirdPartyLinks; let j = index">
                        <div *ngIf="thirdPartyLink.linkType" class="link-item">
                            {{ thirdPartyLink.link }} ({{ getLinkTypeNames(thirdPartyLink.linkType) }})
                        </div>
                    </div>
                </div>
                <div class="edit-button-container">
                    <div class="edit-button" (click)="changeStatus()">
                        <button type="button" class="open-edit">
                            <mat-icon>mode_edit</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #editInProgress>
            <div class="wrapper edit-in-progress">
                <div class="links-container">
                    <div *ngFor="let thirdPartyLink of thirdPartyLinks; let i = index" class="link-row">
                        <div class="link-field">
                            <mat-form-field>
                                <mat-label>Link</mat-label>
                                <input matInput type="text" [(ngModel)]="thirdPartyLink.link"
                                    (change)="onLinkChange(thirdPartyLink, $event)" />
                            </mat-form-field>
                        </div>
                        <div class="label-field">
                            <div class="select">
                                <mat-form-field class="selector selector-link-type">
                                    <mat-label>Type</mat-label>
                                    <mat-select [(value)]="thirdPartyLink.linkType">
                                        <mat-option *ngFor="let type of thirdPartyLinkTypes" [value]="type.value">{{
                                            type.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="label-field">
                            <div class="select">
                                <mat-form-field class="selector selector-order">
                                    <mat-label>Order</mat-label>
                                    <mat-select [(value)]="thirdPartyLink.order"
                                        (selectionChange)="onOrderSelectChange(thirdPartyLink, $event.value)">
                                        <mat-option *ngFor="let order of thirdPartyLinkOrder" [value]="order">{{ order
                                            }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="delete" (click)="deleteThirdPartyLink(thirdPartyLink)">
                            <i style="margin-left: 12px;" class="material-icons">delete</i>
                        </div>
                    </div>
                    <div class="tool-panel-wrapper">
                        <div class="add-one-more">
                            <div [ngClass]="{ 'hidden': !IsShowAddOneMore }" (click)="addThirdPartyLink()">+
                                Add one more</div>
                        </div>
                        <div class="cancel-save-buttons">
                            <button class="cancel-button" (click)="cancelThirdPartyLink()">Cancel</button>
                            <button class="save-button" (click)="saveThirdPartyLinks()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div *ngIf="thirdPartyLinks.length === 0 && !isEdit" class="add-one-more-wraper">
            <div class="add-one-more add-value" (click)="addThirdPartyLink()">
                <div class="add-value-text">+ Add value</div>
            </div>
        </div>
    </div>
</div>