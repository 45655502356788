<div class="settings-table_row row">
    <div class="col-1 cell">{{setting.id}}</div>
    <div class="col cell">
        <div *ngIf="setting.requests.length == 0">
            <b>{{EnumUtil.getBulkRequestStatusViewLabel(RequestStatus.New)}}</b>
        </div>
        <pd-bulk-message-request-tree *ngIf="setting.requests.length > 0" [requests]="setting.requests"
            (onRequestSelectChange)="requestSelectChange($event)">
        </pd-bulk-message-request-tree>
    </div>
    <div class="col cell" [innerHTML]="parseSettingData()">
    </div>
    <div class="col cell">
        @if(selectedRequest?.isSendToReceived != null)
        {
        <div>
            {{getSelectedStatusesMessage(selectedRequest)}}
        </div>
        <br />
        }

        @if(isEmail)
        {
        <ng-container>
            @if(selectedRequest?.messageTemplate?.isNeedUnsubscribeHtml)
            {
            <div>
                Added unsubscribe link
            </div>
            <br />
            }
            @if(!selectedRequest?.messageTemplate?.isNeedUnsubscribeHtml)
            {
            <div>
                No unsubscribe link
            </div>
            <br />
            }
        </ng-container>
        }

        @if(isWhatsapp)
        {
        <div [innerHTML]="parseRequestData(bulkMessageSetting)"></div>
        <div>
            <div><b>Expiration Date: </b><br />{{selectedRequest?.expirationDate
                | date :'dd-MMM-yyyy h:mm a'}}</div><br />
        </div>
        }

        <div><b>{{getTemplateName()}}</b></div>
        <br />
        <div [innerHTML]="getTemplateVariablesHtml(TemplateVariableTypes.Subject)">
        </div>

        @if(selectedRequest?.messageTemplate?.messageTemplateType ==
        MessageTemplateType.Whatsapp)
        {
        <div>{{getTemplateBody(bulkMessageSetting)}}</div>
        }

        @if(selectedRequest?.messageTemplate?.messageTemplateType ==
        MessageTemplateType.Whatsapp)
        {
        <br />
        <div [innerHTML]="getTemplateVariablesHtml(bulkMessageSetting, TemplateVariableTypes.Body)">
        </div>
        }

        @if(selectedRequest?.messageTemplate?.messageTemplateType ==
        MessageTemplateType.Email)
        {
        <button class="btn btn-primary waves-light ml-0" (click)="showTemplate(selectedRequest)">Show
            Template</button>
        }
    </div>
    <div class="col cell">
        <div *ngFor="let location of getLocationBody()">
            {{location.largeLabel}}
        </div>
    </div>
    <div class="col-1 cell">{{setting.created | date :'dd-MMM-yyyy h:mm a'}}</div>
    <div class="col-2 cell">
        <div class="row" *ngIf="!isSelectedItem">
            <div class="col">
                <button class="btn btn-primary waves-light ml-0" (click)="select()">Show
                    Details</button>
            </div>
        </div>
        <div *ngIf="isArchive" class="row">
            <div class="col">
                <button class="btn btn-warning waves-light ml-0" (click)="archive(setting.id, false)">Unarchive</button>
            </div>
        </div>
        <div *ngIf="isSelectedItem">
            <div class="row" *ngIf="!isArchive">
                <div class="col">
                    <button class="btn btn-success waves-light ml-0"
                        (click)="configureWhatsappMessageSending(setting)">Configure Whatsapp
                        Message</button>
                </div>
                <div class="col">
                    <button class="btn btn-primary waves-light ml-0"
                        (click)="configureEmailMessageSending(setting)">Configure Email</button>
                </div>
            </div>
        </div>
        <div *ngIf="isMain">
            <div class="row" *ngIf="setting.requests.length == 0">
                <div class="col">
                    <button class="btn btn-danger waves-light ml-0" (click)="remove(setting.id)">Remove</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-warning waves-light ml-0"
                        (click)="archive(setting.id, true)">Archive</button>
                </div>
            </div>
        </div>
    </div>
</div>