<div class="pd-alert-container">
  <div class="alert">   
    <div class="alert-icon-wrapper" [ngClass]="{ 
      'alert-success': type === AlertTypes.Success,
      'alert-warning': type === AlertTypes.Warning,
      'alert-danger': type === AlertTypes.Error 
    }">
      <div class="icon">
        <fa-icon [icon]="icon"></fa-icon>
      </div>
    </div>
    <div class="alert-message">{{message}}</div>

    <button class="btn" type="button" [ngClass]="{ 
      'btn-success': type === AlertTypes.Success, 
      'btn-warning': type === AlertTypes.Warning,
      'btn-danger': type === AlertTypes.Error 
    }" (click)="closeAlert()">{{buttonText}}</button>
  </div>
</div>