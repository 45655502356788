import { CsvUtil } from 'src/app/core/utils/csv.util';
import { Pipeline } from './../core/models/Pipeline';
import { StageFeatureStoreService } from './../core/services/stores/stage-feature-store.service';
import { PipelineDealsStoreService } from './../core/services/stores/pipeline-deal-store.service';
import { PipelineStoreService } from './../core/services/stores/pipeline-store.service';
import { UserStoreService } from './../core/services/stores/user-store.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { MasterLeadComponent } from './master-lead/master-lead.component';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as stages from '../../assets/stages_new_prospespects';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription, firstValueFrom, from, take, takeUntil } from 'rxjs';
import { RolesEnum } from '../core/enums/RolesEnum';
import { MultiSelectDataType } from '../core/enums/MultiSelectDataType';
import { GlobalConstants } from '../core/global-constants';
import { Stage } from '../core/models/Stage';
import { User } from '../core/models/UserModel';
import { ChipsSelectFilterDataModel } from '../core/models/CustomChipsSelectFilterModel';
import { Deal } from '../core/models/Deal';
import { UsersService } from '../core/services/users.service';
import { DealService } from '../core/services/deals.service';
import { FilterDealModel } from '../core/models/filter-models/FilterDealModel';
import { RoleStageFilter } from '../core/models/RoleStageFilter';
import * as rottenTimeModalOptions from 'src/app/shared/custom/modal-options/custom-rotten-time.options';
import { CustomRottenTimeModalComponent } from '../shared/custom/custom-rotten-time-modal/custom-rotten-time-modal.component';
import { KillRecordOptions } from '../shared/custom/modal-options/kill-record.options';
import { DealDetails } from '../core/models/DealDetails';
import { DateHelper } from '../core/utils/date.helper';
import { MeetingPrepComponent } from '../shared/custom/meeting-prep/meeting-prep.component';
import { WardrobePlanComponent } from '../shared/custom/wardrobe-plan/wardrobe-plan.component';
import { CustomUploadWardrobePlanModalComponent } from '../shared/custom/custom-wardrobe-plan-upload-modal/custom-wardrobe-plan-upload-modal.component';
import { NoteService } from '../core/services/note.service';
import { ExportService } from '../core/services/export.service';
import { PreviousRouteService } from '../core/services/previousRoute.service';
import { CustomKillRecordModalComponent } from '../shared/custom/custom-kill-record-modal/custom-kill-record-modal.component';
import { CustomModalComponent } from '../shared/custom/custom-modal/custom-modal.component';
import { CustomReferralModalComponent } from '../shared/custom/custom-referral-modal/custom-referral-modal.component';
import { CustomUploadEaFollowUpImageModalComponent } from '../shared/custom/custom-ea-follow-up-image-upload-modal/custom-ea-follow-up-image-upload-modal.component';
import { CustomMeetingComponent } from '../shared/custom/custom-meeting/custom-meeting.component';
import { CustomMeetingDateModalComponent } from '../shared/custom/custom-meeting-date/custom-meeting-date.component';
import { CustomAddressConfirmComponent } from '../shared/custom/custom-address-confirm/custom-address-confirm.component';
import { CustomMeetingConfirmComponent } from '../shared/custom/custom-meeting-confirm/custom-meeting-confirm.component';
import { CustomContactsModalComponent } from '../shared/custom/custom-contacts-modal/custom-contacts-modal.component';
import { BasketClientCardModalComponent } from './basket-client-card-modal/basket-client-card-modal.component';
import { RightDealsPanelComponent } from './right-deals-panel/right-deals-panel.component';
import { saveAs } from 'file-saver';
import { ExportComponent } from '../shared/custom/export/export.component';
import { ListDealComponent } from './list-deal/list-deal.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../core/base.component';

@Component({
  selector: 'pd-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})

export class DealsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public allPipelines = stages.ALL_PIPELINES;
  public listDealTotalCount: number | null;
  public rolesEnum = RolesEnum;
  public selectDataTypes = MultiSelectDataType;
  public ALL_STAGES = GlobalConstants.ALL_STAGES;
  public ROUTE_NAMES = GlobalConstants.ROUTE_NAMES;
  public PIPELINES = stages.Pipelines;
  minStage: any;
  public array: any;
  public blockedStages: Stage[];
  public dealsCountByStage: any[];
  public dealsLifeTimeSpendByStage: any[];
  public lifeTimeSpendTotal: number;
  public activeStages$: Observable<Stage[]>;
  public stagesOnFilteredUsers: Stage[];
  public activeStages: Stage[] = [];
  public stagesFilterForCurrentUser$: Observable<Stage[]>;
  public lazyLoading = false;
  @ViewChild('container') container: any;
  @ViewChildren('dragulaContainer') dragulaContainers: any
  public wrappMinHeight: string;
  @ViewChild('listDeal') listDeal: ListDealComponent;
  @ViewChild('masterLeadView') masterLeadView: MasterLeadComponent;
  @ViewChild('referralModal') referralModal: CustomReferralModalComponent;
  @ViewChild('exportFileModal') exportFileModal: ExportComponent;
  @ViewChild('wardrobePlan') wardrobePlan: WardrobePlanComponent;
  @ViewChild('meetingPrep') meetingPrep: MeetingPrepComponent;
  @ViewChild('uploadWardrobePlan') uploadWardrobePlan: CustomUploadWardrobePlanModalComponent;
  @ViewChild('eaFollowUpImageUpload') eaFollowUpImageUpload: CustomUploadEaFollowUpImageModalComponent;
  @ViewChild('exportEmailsModal') exportEmailsModal: CustomModalComponent;
  public exportEmailsModalTitle = "Deal Count";
  public exportEmailsModalMaxLimit = null;
  @ViewChild('exportLookupsModal') exportLookupsModal: CustomModalComponent;
  public exportLookupsModalTitle = "Deal Count";
  public exportLookupsModalMaxLimit = 10000;
  @ViewChild('findEmailsModal') findEmailsModal: CustomModalComponent;
  public findEmailsModalTitle = "Range Deals";
  public findEmailsModalMaxLimit = null;
  @ViewChild('meetingDateModal') meetingDateModal: CustomMeetingDateModalComponent;
  @ViewChild('rottenTimeModal') rottenTimeModal: CustomRottenTimeModalComponent;
  @ViewChild('filters') filters: RightDealsPanelComponent;

  public selectedPipelineId: number;
  public start: any;
  public spinner = true;
  public dropSpinner = false;
  public idPipeline$: Observable<number>;
  public titleArray = [];
  public users = [];
  public currentUser: User;
  public loadedDealsUsersFilter: User[];
  public currentUserFilter: User[];
  public stagesFilter$: Observable<Stage[]>;
  @Output() listChange = new EventEmitter<Object>();
  public isListDeals = false;
  @Input() listLoading: any;
  public isMasterLeadDeals = false;
  public dealReqest: Subscription;

  public allStages = { text: 'All Stages', value: -1 };

  public selectedGroupId: number;
  public selectedGenderId: number;
  public filteredCompanies: Array<ChipsSelectFilterDataModel>;
  public filteredPositions: Array<ChipsSelectFilterDataModel>;
  public filteredLocations: Array<ChipsSelectFilterDataModel>;

  public infoMessage = null;

  @Output() isDashboard: boolean;

  public isMoreDeals: boolean;
  public isShowError = false;
  public isHotlistSent = false;
  public isRecentlyRequestedHotlist = false;
  public requestedHotlistDate: string = '';
  public movedDeal: any;
  public orderShippedChangedDeal: Deal
  public openOrderNotifiedChangedDeal: Deal
  public isDeliveryEmailSentChangedDeal: Deal

  public isCampaignDeals: boolean = false;

  public isAdmin = false;
  public isMasterLeadAccount = false;
  public isSystemAccount = false;
  public isAdminAccount = false;
  public isClothierAccount = false;
  public isEaAccount = false;
  public isEaCallerAccount = false;
  public isMissedMtg = false;
  public isMyDeals = false;

  public isAllowedBookingForSelf = false;
  public isAllowedBookingForSomeoneElse = false;
  public isAllowedBookingSM1ForSelf = false;
  public isAllowedBookingSM1ForSomeoneElse = false;
  public isShoweditClientCard = true;

  public stylesForColumn = {};

  @Output() basketClientCardDeal: Deal;
  @Output() dealToReferral: any;
  private referralDeal: Deal;

  @ViewChild('killRecordModal') killRecordModal: CustomKillRecordModalComponent;
  @ViewChild('trackingLinkModal') trackingLinkModal: CustomKillRecordModalComponent;
  public killRecordOptions: KillRecordOptions;
  public trackingLinkOptions: KillRecordOptions;
  public trackingLinkMessageText = "Please enter the tracking link";
  public trackingLinkPlaceholder = "Link";
  public trackingLinkConfirmButtonText = "Save";

  userIdsForLastGetDealsRequest: any;

  public isContactByEmailOnlyFilter: boolean;
  public isSameBuildingFilter: boolean;
  public isWithPhoneNumberOnlyFilter: boolean;
  public isAllowToDrag = true;
  public loadedUsers: User[];

  public async ngOnInit(): Promise<void> {
    const result = await this.usersService.getUsersByRoles();
    this.currentUser = await firstValueFrom(this.userContextService.user);

    this.userStoreService.setAllUsers(result);
    if (this.currentUser.role_id === RolesEnum.MasterLead || this.currentUser.role_id === RolesEnum.Admin) {
      this.loadedUsers = this.setUsersFilter();
    }
    else {
      if (this.currentUser.role_id !== RolesEnum.EA && this.currentUser.role_id !== RolesEnum.EaCaller) {
        this.loadedUsers = [this.currentUser];
      }
    }

    if (this.loadedUsers) {
      this.userStoreService.setSelectedUsersFilter(this.loadedUsers);
    }

    this.previousRouteService.getPreviousUrl();
    this.setUpModals();
    this.users = await this.usersService.getUsers();
    if (this.users) {
      this.users.unshift({ id: -1, name: 'Everyone', role_id: 7 });
    }

    console.log('DealsComponent ngOnInit setAllStage');
    this.stageFeatureStoreService.setAllStage(stages.STAGES_FOR_ALL_PIPELINES as Stage[]);
    this.pipelineStoreService.currentPipeline.pipe(takeUntil(this.destroy$)).subscribe(pipeline => {
      if (!pipeline) return;
      this.stageFeatureStoreService.setSelectedPipeline(pipeline.id);
      this.selectedPipelineId = pipeline.id;
    });

    this.initializeStore();

    this.start = 0;
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params['id'];
      if (!isNaN(Number(id))) {
        this.trySetStorePipeline(Number(id));
        this.isMasterLeadDeals = false;
        this.isDashboard = true;
        this.isListDeals = false;
      } else {
        if (id === 'masterLeadDeals') {
          this.isMasterLeadDeals = true;
        } else {
          this.isListDeals = true;
        }
      }
    });
  }

  constructor(
    private dialog: MatDialog,
    private exportService: ExportService,
    public usersService: UsersService,
    public dealService: DealService,
    public dragulaService: DragulaService,
    private router: Router,
    private route: ActivatedRoute,
    private previousRouteService: PreviousRouteService,
    private noteService: NoteService,
    private userContextService: UserContextService,
    private userStoreService: UserStoreService,
    private pipelineStoreService: PipelineStoreService,
    private pipelineDealsStoreService: PipelineDealsStoreService,
    private stageFeatureStoreService: StageFeatureStoreService) {
    super();
    if (!dragulaService.find('first-bag')) {
      dragulaService.createGroup('first-bag', {
        direction: 'horizontal',
        moves: (el: any, container: any, handle: any) => {
          if (el.classList.contains('hidden-deals') || handle.classList.contains('nodrag') || container.classList.contains('nodrag')) {
            return false;
          }
          return true;
        },
        removeOnSpill: false,
        revertOnSpill: true
        // liftDelay: 700
      });
    }

    dragulaService.dropModel().pipe(takeUntil(this.destroy$)).subscribe((args: any) => {
      const pipeline = pipelineStoreService.currentPipeline.value;
      this.dropSpinner = true;
      setTimeout(() => {
        this.dropSpinner = false;
      }, 500);
      const el = args.el;
      const target = args.target;
      const source = args.source;
      const dealId = +el.dataset.id;
      const stageId = +target.dataset.id;
      const previousStageId = +source.dataset.id;

      if (stageId === previousStageId) return;
      if (!this.array) return;

      let dealDetails = this.array[previousStageId].find(deal => deal.id === dealId);
      let meetingDate = dealDetails.meeting_date_utc;

      if (this.array[previousStageId]) {
        const index = this.array[previousStageId].findIndex(deal => deal.id === dealId);
        this.array[stageId].push(this.array[previousStageId][index]);
        this.array[previousStageId].splice(index, 1);
      }

      this.movedDeal = {
        dealId: el.dataset.id,
        previousLabeledStageId: el.dataset.previousLabeledStageId,
        stageId: target.dataset.id,
        previousStageId: source.dataset.id,
        personId: dealDetails.personId,
      };

      let meetingDateModal = this.meetingDateModal;

      this.dropSpinner = true;
      this.dealService.checkExtraDealMoveRules(dealId, stageId).then((response) => {
        console.log('checkExtraDealMoveRules', response);
        this.dropSpinner = false;
        if (!response.status) {
          alert(response.message)
          this.returnDeal(this.movedDeal);
          return;
        }

        let previousStage = stages.STAGES_FOR_ALL_PIPELINES.find(x => x.id == previousStageId);
        let stage = stages.STAGES_FOR_ALL_PIPELINES.find(x => x.id == stageId)
      
        if (pipeline.id === stages.Pipelines.Clients) {
          if ((this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.role_id === RolesEnum.Admin
            || this.currentUser.role_id === RolesEnum.EA
            || this.currentUser.role_id === RolesEnum.EaCaller)) {
            //PD-1713 - Ability to change status from "Recently Contacted" to "EA Current Meeting"
            if (previousStage.id === this.ALL_STAGES.RecentlyContacted) {
              if (stage.id == this.ALL_STAGES.EACurrentMeeting) {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true);
                  return;
                }
              }
            }
          }
          if ((this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.role_id === RolesEnum.Admin
            || this.currentUser.role_id === RolesEnum.Clothier)) {
            //PD-1713 - Ability to change status from "Recently Contacted" to "Current Meeting"
            if (previousStage.id === this.ALL_STAGES.RecentlyContacted) {
              if (stage.id == this.ALL_STAGES.CurrentMeeting) {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true);
                  return;
                }                         
              } 
            }
          }

          if (stage && (this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.role_id === RolesEnum.Admin
            || this.currentUser.role_id === RolesEnum.Clothier)) {
            //PD-1745 - Ability to change status from "CashedOut" to "Recently Contacted"
            if (previousStage.id === this.ALL_STAGES.CashedOut) {
              if (stage.id === this.ALL_STAGES.RecentlyContacted) { }
              this.dragulaUpdateStage(dealId, stageId, previousStageId);
              return;
            }
          }
        }

        //Only MasterLead is able to move deals from all clients' pipeline stages to all clients' pipeline stages
        if (this.isMasterLeadAccount && (pipeline.id === stages.Pipelines.Clients || pipeline.id === stages.Pipelines.OpenOrders)) {
          if (stages.CLIENT_MOVE_RULES_MasterLead[previousStage.id].some(ss => ss == stage.id)) {
            if (stageId == this.ALL_STAGES.EACurrentMeeting || stageId === this.ALL_STAGES.MeetingSet) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) { 
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), true, false);
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                } 
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true);
                  return;
                }
              }         
            }
            else {
              this.dragulaUpdateStage(dealId, stageId, previousStageId);
              return;
            }
          }
          else {
            this.returnDeal(this.movedDeal)
            return
          }
        }

        if (!this.isMasterLeadAccount && (pipeline.id === stages.Pipelines.Clients || pipeline.id === stages.Pipelines.OpenOrders)) {
          if (stages.CLIENT_MOVE_RULES[previousStage.id].some(ss => ss == stage.id)) {
            if (stageId === this.ALL_STAGES.EACurrentMeeting || stageId === this.ALL_STAGES.MeetingSet) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) { 
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), true, false);
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true);
                  return;
                }
              }
            }
            else {
              this.dragulaUpdateStage(dealId, stageId, previousStageId);
              return;
            }
          }
          else {
            this.returnDeal(this.movedDeal)
            return;
          }
        }

        if (!this.isMasterLeadAccount && pipeline.id === stages.Pipelines.ClothierContactClients) {
          if (stages.CLOTHIERCONTACTCLIENTS_MOVE_RULES[previousStage.id].some(ss => ss == stage.id)) {
            if (stageId === this.ALL_STAGES.CurrentMeeting) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) { 
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), true, false);
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true);
                  return;
                }
              }
            } else if (stageId === this.ALL_STAGES.DeliveryMeeting) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) {
                  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal), true, false)
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal), false, true)
                  return;
                }
              }
            } else if (stageId == this.ALL_STAGES.TryNextSeason) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.TryNextSeasonOptions);
              return;
            } else if (stageId == this.ALL_STAGES.Reschedule) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.RescheduleOptions);
              return;
            } else if (stageId == this.ALL_STAGES.InAlterations) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.InAlterationsOptions);
              return;
            }
            else {
              this.dragulaUpdateStage(dealId, stageId, previousStageId);
              return;
            }
          }
          else {
            this.returnDeal(this.movedDeal)
            return
          }
        }

        //Only MasterLead is able to move deals from all clients' pipeline stages to all clients' pipeline stages
        if (this.isMasterLeadAccount && pipeline.id === stages.Pipelines.ClothierContactClients) {
          if (stages.CLOTHIERCONTACTCLIENTS_MOVE_RULES_MasterLead[previousStage.id].some(ss => ss == stage.id)) {
            if (stageId === this.ALL_STAGES.CurrentMeeting) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), true, false)
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmModal(dealDetails, meetingDateModal), false, true)
                  return;
                }
              }
            } else if (stageId === GlobalConstants.ALL_STAGES.DeliveryMeeting) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) {
                  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal);
                  return;
                } 
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal), true, false);
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== ""))  {
                  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomAddressConfirmMaxDateModal(dealDetails, meetingDateModal), false, true);
                  return;
                }
              }
            } else if (stageId == this.ALL_STAGES.TryNextSeason) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.TryNextSeasonOptions);
              return;
            } else if (stageId == this.ALL_STAGES.Reschedule) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.RescheduleOptions);
              return;
            } else if (stageId == this.ALL_STAGES.InAlterations) {
              this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.InAlterationsOptions);
              return;
            }
            else {
              this.dragulaUpdateStage(dealId, stageId, previousStageId);
              return;
            }
          }
          else {
            this.returnDeal(this.movedDeal)
            return
          }
        }

        if (stageId === this.ALL_STAGES.Referral && this.currentUser.role_id === RolesEnum.BB) {
          this.returnDeal(this.movedDeal)
          return;
        }

        if (stageId === this.ALL_STAGES.Meeting) { 
          if (this.currentUser.role_id === RolesEnum.BB) {
            if (dealDetails.phone.some(phone => phone.value !== "")) {
              this.openCustomMeetingModal(dealDetails, meetingDate, stageId);
              return;
            }
            else {
              this.openRequiredCustomContactsModal(dealDetails, () => this.openCustomMeetingModal(dealDetails, meetingDate, stageId), true, false);
              return;
            }
          }
          else {
            if (dealDetails.email.some(email => email.value !== "")) {
              this.openCustomMeetingModal(dealDetails, meetingDate, stageId)
              return;
            }
            else {
              this.openRequiredCustomContactsModal(dealDetails, () => this.openCustomMeetingModal(dealDetails, meetingDate, stageId), false, true);
              return;
            }
          }
        }

        if (stage && stage.pipeline_id == stages.Pipelines.ClothierMeetingConfirm) {
          if (stages.MEETING_CONFIRM_MOVE_RULES[previousStage.id].some(ss => ss == stage.id)) {
            if (stageId === GlobalConstants.ALL_STAGES.Confirmed) {
              if (this.currentUser.role_id === RolesEnum.BB) {
                if (dealDetails.phone.some(phone => phone.value !== "")) {
                  this.openCustomMeetingConfirmModal(dealDetails, meetingDate);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () => this.openCustomMeetingConfirmModal(dealDetails, meetingDate), true, false)
                  return;
                }
              }
              else {
                if (dealDetails.email.some(email => email.value !== "")) {
                  this.openCustomMeetingConfirmModal(dealDetails, meetingDate);
                  return;
                }
                else {
                  this.openRequiredCustomContactsModal(dealDetails, () =>  this.openCustomMeetingConfirmModal(dealDetails, meetingDate), false, true)
                  return;
                }
              }
            }
            this.dragulaUpdateStage(dealId, stageId, previousStageId)
            return
          }
          else {
            this.returnDeal(this.movedDeal)
            return
          }
        }

        if (previousStageId === this.ALL_STAGES.Referral &&
          (((stageId === this.ALL_STAGES.Cold || stageId === this.ALL_STAGES.Linkedin || stageId === this.ALL_STAGES.SameBuilding || stageId === this.ALL_STAGES.SM ||
            stageId === this.ALL_STAGES.SM1 || stageId === this.ALL_STAGES.Float || stageId === this.ALL_STAGES.Resched)
            && !this.isAdmin) || (stageId === this.ALL_STAGES.VM1 || stageId === this.ALL_STAGES.VM2 || stageId === this.ALL_STAGES.VM3))) {
          this.returnDeal(this.movedDeal)
          return
        }

        if ((previousStageId === this.ALL_STAGES.RefVM1 || previousStageId === this.ALL_STAGES.RefVM2 || previousStageId === this.ALL_STAGES.RefVM3) &&
          (stageId !== this.ALL_STAGES.Meeting && stageId !== this.ALL_STAGES.NoPhone && stageId !== this.ALL_STAGES.Kill &&
            stageId !== this.ALL_STAGES.NG && stageId !== this.ALL_STAGES.NoAns && stageId !== this.ALL_STAGES.RefVM1 && stageId !== this.ALL_STAGES.RefVM2 && stageId !== this.ALL_STAGES.RefVM3)
          && !this.isAdmin) {
          this.returnDeal(this.movedDeal)
          return
        }

        if ((previousStageId !== this.ALL_STAGES.Referral && previousStageId !== this.ALL_STAGES.RefVM1 && previousStageId !== this.ALL_STAGES.RefVM2 && previousStageId !== this.ALL_STAGES.RefVM3)
          && (stageId === this.ALL_STAGES.RefVM1 || stageId === this.ALL_STAGES.RefVM2 || stageId === this.ALL_STAGES.RefVM3) && !this.isAdmin) {
          this.returnDeal(this.movedDeal)
          return
        }

        if (stageId === this.ALL_STAGES.SM1) {
          this.dialog.open(CustomMeetingComponent, {
            disableClose: true,
            maxWidth: "90vw",
            data: {
              moveLeadsToUser: this.moveLeadsToUser.bind(this),
              closeModal: this.closeDateMeetingModal.bind(this),
              saveDate: this.hideMeetingModal.bind(this),
              dealsPerson: dealDetails,
              meetingDate: meetingDate,
              stageId: stageId,
            }
          });
          return;
        }

        if (stageId === this.ALL_STAGES.Float && !this.isAdmin && this.currentUser.role_id !== RolesEnum.Clothier) {
          this.returnDeal(this.movedDeal);
          return;
        }

        if (previousStageId === this.ALL_STAGES.Meeting && stageId === this.ALL_STAGES.NG
          && this.currentUser && this.currentUser.role_id !== RolesEnum.Admin && this.currentUser.role_id !== RolesEnum.MasterLead) {
          this.returnDeal(this.movedDeal)
          return
        }

        // allow only move from Meeting to ClientSaved
        if (previousStageId !== this.ALL_STAGES.Meeting && stageId === this.ALL_STAGES.ClientSaved) {
          this.returnDeal(this.movedDeal);
          return;
        }

        if (stageId === this.ALL_STAGES.ClientSaved) {
          let main = this;

          this.dialog.open(CustomAddressConfirmComponent, {
            disableClose: true,
            maxWidth: "100vw",
            data: {
              closeModal: this.closeDateMeetingModal.bind(this),
              saveDate: this.confirmAddressModal.bind(this),
              dealsPerson: dealDetails,
              confirmCallback: function () { main.dragulaUpdateStage(dealId, stageId, previousStageId); },
            }
          });
          return;
        }

        if (stageId === this.ALL_STAGES.Kill || stageId === this.ALL_STAGES.KilledRecord) {
          if (this.currentUser.is_admin && this.currentUser.role_id !== RolesEnum.EA && this.currentUser.role_id !== RolesEnum.EaCaller)
            this.killRecordModal.openModal(null);
          else
            this.killRecordModal.openModal(null);
          return;
        }

        if (stageId === this.ALL_STAGES.Referral) {
          this.referralDeal = dealDetails;
          this.referralModal.openReferralModal(this.referralDeal, null);
          return;
        }

        this.dragulaUpdateStage(dealId, stageId, previousStageId);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.currentUser) {
      this.currentUser = null;
      this.stageFeatureStoreService.reset();
      this.pipelineDealsStoreService.reset();
      this.userStoreService.reset();
      this.pipelineStoreService.reset();
    }

    super.ngOnDestroy();
  }

  private setUsersFilter(): User[] {
    const savedFilter = JSON.parse(localStorage.getItem('filterUsers'));
    return savedFilter ? savedFilter : [this.currentUser];
  }

  updateStagesLength() {
    let height = this.dragulaContainers && this.dragulaContainers._results && Math.max.apply(null, (this.dragulaContainers._results.map(r => r.nativeElement.offsetHeight)));
    this.wrappMinHeight = typeof height == "number" && height !== Infinity && height !== -Infinity && height > 0 ? height + "px" : "100%";
  }

  private trySetStorePipeline(id: number) {
    const allPipelines = this.getAvaliablePipelines();
    let pipeline = allPipelines.find(p => p.id === id);
    if (allPipelines && allPipelines.length > 0 && !pipeline) {
      console.log('trySetStorePipeline setCurrentPipeline1');
      this.pipelineStoreService.setCurrentPipeline(allPipelines[0]);
    }
    else if (allPipelines && allPipelines.length > 0 && pipeline) {
      console.log('trySetStorePipeline setCurrentPipeline2');
      this.pipelineStoreService.setCurrentPipeline(pipeline);
    }
  }

  private getAvaliablePipelines(): Pipeline[] {
    const usersFilter = this.userStoreService.selectedUsersFilter.value;
    const allFeatureItems = stages.ALL_PIPELINES;
    const currentUser = this.currentUser;
    if (((currentUser && currentUser.role_id) || (usersFilter && usersFilter.length > 0 && usersFilter[0])) && allFeatureItems && allFeatureItems.length > 0) {
      if (currentUser.is_admin && currentUser.role_id !== RolesEnum.EA && currentUser.role_id !== RolesEnum.EaCaller) {
        if (usersFilter && usersFilter.length !== 0 && (usersFilter[0].role_id === RolesEnum.Admin || usersFilter[0].role_id === RolesEnum.MasterLead))
          return allFeatureItems;
        else if (!usersFilter || usersFilter.length == 0 || usersFilter[0].is_admin)
          if (currentUser.role_id === RolesEnum.MasterLead)
            return allFeatureItems;
          else
            return allFeatureItems.filter(p => p.id !== stages.Pipelines.ClothierMeetingConfirm);
        else if (usersFilter && usersFilter[0] && (usersFilter[0].role_id === RolesEnum.Clothier || usersFilter[0].role_id === RolesEnum.MasterLead))
          return allFeatureItems
      }
      if ((currentUser.role_id === RolesEnum.EaCaller) || (usersFilter && usersFilter.length > 0 && (usersFilter[0].role_id === RolesEnum.EaCaller))) {
        return allFeatureItems.filter(p => p.id === stages.ClientsPipeline.id || p.id === stages.ClothierContactClientsPipeline.id);
      } else if ((currentUser.role_id === RolesEnum.EA) || (usersFilter && usersFilter.length > 0 && (usersFilter[0].role_id === RolesEnum.EA))) {
        return allFeatureItems.filter(p => p.id === stages.OpenOrders.id || p.id === stages.ClothierContactClientsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.Clothier) {
        if (currentUser.role_id === RolesEnum.Clothier)
          return allFeatureItems
        else if (currentUser.is_admin)
          return allFeatureItems
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.BB) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.User) {
        return allFeatureItems.filter(p => p.id !== stages.ClothierMeetingConfirmPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.EmailMarketing) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else if (usersFilter && usersFilter.length > 0 && usersFilter[0].role_id === RolesEnum.Recruiter) {
        return allFeatureItems.filter(p => p.id === stages.NewProspectsPipeline.id);
      } else {
        return allFeatureItems.filter(p => p.id === stages.ClientsPipeline.id || p.id === stages.OpenOrders.id || p.id === stages.NewProspectsPipeline.id);
      }
    }
    return [];
  }

  private initializeStore() {
    this.isAdmin = this.currentUser && this.currentUser.is_admin;
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
    this.isSystemAccount = this.currentUser && this.currentUser.role_id === RolesEnum.SystemAccount;
    this.isAdminAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Admin;
    this.isClothierAccount = this.currentUser && this.currentUser.role_id === RolesEnum.Clothier;
    this.isEaAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EA;
    this.isEaCallerAccount = this.currentUser && this.currentUser.role_id === RolesEnum.EaCaller;
    this.isCampaignDeals = this.currentUser && this.currentUser.role_id === RolesEnum.CampaignAccount;
    this.isMissedMtg = this.currentUser && this.currentUser.role_id === RolesEnum.MissedAccount;
    this.userStoreService.selectedUsersFilter.pipe(takeUntil(this.destroy$)).subscribe(users => {
      this.loadedDealsUsersFilter = users;
      if ((this.loadedDealsUsersFilter && this.loadedDealsUsersFilter.length === 1) && this.currentUser && this.currentUser.id === this.loadedDealsUsersFilter[0].id) {
        this.isMyDeals = true;
      }
      else {
        this.isMyDeals = false;
      }

      if (users && users.length > 0) {
        this.isCampaignDeals = RolesEnum.CampaignAccount === users[0].role_id;
        this.isMissedMtg = RolesEnum.MissedAccount === users[0].role_id;
      }
    });

    this.pipelineStoreService.currentPipeline.pipe(takeUntil(this.destroy$)).subscribe(pipeline => {
      if (!pipeline) return;
      this.isAllowToDrag = !(pipeline.id === this.PIPELINES.ClothierContactClients && this.currentUser &&
        (this.currentUser.role_id === RolesEnum.EA || this.currentUser.role_id === RolesEnum.EaCaller));
    });

    this.currentUserFilter = this.userStoreService.selectedUsersFilter.value;

    this.isAllowedBookingForSelf = this.currentUser.role_id !== RolesEnum.BB &&
      this.currentUser.role_id !== RolesEnum.DirectMailAccount &&
      this.currentUser.role_id !== RolesEnum.CampaignAccount && (this.currentUserFilter
        && this.currentUserFilter.length > 0
        && this.currentUserFilter[0].role_id !== RolesEnum.BB
        && this.currentUserFilter[0].role_id !== RolesEnum.DirectMailAccount
        && this.currentUserFilter[0].role_id !== RolesEnum.CampaignAccount);

    this.isAllowedBookingForSomeoneElse = this.currentUser && this.currentUser.role_id !== RolesEnum.Clothier &&
      this.currentUserFilter && this.currentUserFilter[0] && this.currentUserFilter[0].role_id !== RolesEnum.Clothier;

    this.isAllowedBookingSM1ForSelf = this.currentUser.role_id !== RolesEnum.BB &&
      this.currentUser.role_id !== RolesEnum.DirectMailAccount &&
      this.currentUser.role_id !== RolesEnum.CampaignAccount && (this.currentUserFilter
        && this.currentUserFilter.length > 0
        && this.currentUserFilter[0].role_id !== RolesEnum.BB
        && this.currentUserFilter[0].role_id !== RolesEnum.DirectMailAccount
        && this.currentUserFilter[0].role_id !== RolesEnum.CampaignAccount);

    this.isAllowedBookingSM1ForSomeoneElse = this.currentUser && this.currentUserFilter && this.currentUserFilter[0] != null;

    this.setActiveStages();

    this.userStoreService.loadedDealsUsersFilter.pipe(takeUntil(this.destroy$)).subscribe(users => {
      this.array = this.pipelineDealsStoreService.getPipelineDealToStageByStages(this.stageFeatureStoreService.getStagesForCurrentRoleAndCurrentPipeline.value, users);
    });

    this.pipelineDealsStoreService.additionalData.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dealsCountByStage = this.pipelineDealsStoreService.selectPipelineDealFeatureDatabaseCountByStage();
      this.dealsLifeTimeSpendByStage = this.pipelineDealsStoreService.selectPipelineDealFeatureDatabaseLifeTimeSpendByStage();
    });

    this.pipelineDealsStoreService.deals.pipe(takeUntil(this.destroy$)).subscribe((deals) => {
      console.log("pipelineDealsStoreService deals", deals);
      if (!deals) {
        return;
      }
      this.array = this.pipelineDealsStoreService.getPipelineDealToStageByStages(this.stageFeatureStoreService.getStagesForCurrentRoleAndCurrentPipeline.value);
      this.blockedStages = this.stageFeatureStoreService.selectStageToPipelineDealBlockedStages(deals, this.pipelineDealsStoreService.additionalData.value, this.currentUser);
      this.stageFeatureStoreService.getStagesForCurrentRoleAndCurrentPipeline.pipe(takeUntil(this.destroy$)).subscribe(allowedStages => {
        if (this.dealsLifeTimeSpendByStage && this.dealsLifeTimeSpendByStage.length > 0 && allowedStages && allowedStages.length > 0) {
          let lifeTimeSpendByAllowedStage = this.dealsLifeTimeSpendByStage.filter(l => allowedStages.find(s => s.id === this.dealsLifeTimeSpendByStage.indexOf(l)));
          this.lifeTimeSpendTotal = (lifeTimeSpendByAllowedStage && lifeTimeSpendByAllowedStage.length > 0) ? lifeTimeSpendByAllowedStage.reduce((p, c) => p + c) : 0;
        }
        else {
          this.lifeTimeSpendTotal = 0;
        }
      });
    });
  }

  public isClient(stageId) {
    return stageId === GlobalConstants.ALL_STAGES.DraftOrder
      || stageId === GlobalConstants.ALL_STAGES.DraftConfirmed
      || this.selectedPipelineId === this.PIPELINES.Clients
      || this.selectedPipelineId === this.PIPELINES.OpenOrders
      || this.selectedPipelineId === this.PIPELINES.ClothierContactClients
  }

  private async trackDealsReloadCases(pipelineId): Promise<void> {
    if (this.pipelineStoreService.previousPipeline?.id == pipelineId) return;

    console.log("trackDealsReloadCases pipelineId", pipelineId);
    const users = await firstValueFrom(this.userStoreService.selectedUsersFilter);
    const currentUser = await firstValueFrom(this.userContextService.user)
    console.log("trackDealsReloadCases users", users);
    let usersData = null;
    if ((!users || users.length === 0) && pipelineId) {
      if (currentUser.role_id !== RolesEnum.EA && currentUser.role_id !== RolesEnum.EaCaller) {
        usersData = [currentUser];
        this.userStoreService.setSelectedUsersFilter(usersData);
      }
      else {
        const allUsersWithClientsPipelineAccess = Object.entries(stages.STAGES_BY_ROLES_VISIBILITY).reduce((result, current) => {
          if (current[1] && current[1].length > 0 && current[1].some(stageName =>
            stages.STAGES_FOR_ALL_PIPELINES.some(s => s.name === stageName && pipelineId == s.pipeline_id))) {
            result.push(RolesEnum[current[0]]);
          }
          return result;
        }, []);
        const allUsers = await this.usersService.getUsers();
        usersData = allUsers.filter(i => i.role_id !== RolesEnum.MasterLead &&
          i.role_id !== RolesEnum.EA && i.role_id !== RolesEnum.EaCaller &&
          allUsersWithClientsPipelineAccess.some(r => r === i.role_id));

        this.userStoreService.setSelectedUsersFilter(usersData);
      }
    }
    if (!this.isListDeals && pipelineId) {
      this.setRole();
      await this.proceedToGetDeals();
    }
  }

  private async proceedToGetDeals() {
    this.spinner = true;
    this.start = 0;

    if (this.isCampaignDeals) {
      this.getCampaignDeals();
    } else if (this.isMissedMtg) {
      this.getMissedMgtDeals();
    } else {
      await this.getDealsForRole();
    }
  }

  private setUpModals() {
    this.killRecordOptions = new KillRecordOptions()
    this.trackingLinkOptions = new KillRecordOptions()
    this.trackingLinkOptions.MessageText = this.trackingLinkMessageText
    this.trackingLinkOptions.ConfirmBtnText = this.trackingLinkConfirmButtonText
  }

  private async getDealsForRole() {
    const users = this.userStoreService.selectedUsersFilter.value;
    if (this.currentUser
      && (this.currentUser.role_id !== RolesEnum.EA
        && this.currentUser.role_id !== RolesEnum.EaCaller
        && this.currentUser.role_id !== RolesEnum.EmailMarketing)
      || (users && users.length > 0)) {
      if (users && users.length > 0) {
        if (users[0].role_id === RolesEnum.CampaignAccount) {
          this.isCampaignDeals = true;
        }
        else if (users[0].role_id === RolesEnum.MissedAccount) {
          this.isMissedMtg = true;
        }
      }
    }
    await this.getDeals();
  }

  private setActiveStages() {
    this.setRole();

    this.stageFeatureStoreService.getStagesForCurrentRoleAndCurrentPipeline.pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        console.log("setActiveStages", x);
        this.stagesOnFilteredUsers = x;
        console.log("stagesOnFilteredUsers", this.stagesOnFilteredUsers, this.pipelineStoreService.currentPipeline.value);

        if (x && x.length > 0) {
          this.activeStages = x;
          x.forEach(stage => {
            this.stylesForColumn[stage.name] = {};
            this.stylesForColumn[stage.name]['border-color'] =
              stages.STAGES_COLORS_DICT[stage.name] ? stages.STAGES_COLORS_DICT[stage.name] : 'grey';
            this.stylesForColumn[stage.name]['width'] = 'calc(100% / ' + (this.activeStages.length) + ')';
            if (this.stylesForColumn[stage.name]['border-color'] === 'grey') {
              this.stylesForColumn[stage.name]['padding'] = '3px 2px';
              this.stylesForColumn[stage.name]['border-width'] = 'thin';
            }
          });
        }
      });

    this.stylesForColumn = {};
  }

  ngAfterViewInit() {
    if (this.isListDeals) { //TODO: store filters after list reload?
      this.filters.resetFilters();
    }
    this.pipelineStoreService.currentPipeline.pipe(takeUntil(this.destroy$)).subscribe(async pipeline => {
      console.log("ngAfterViewInit pipeline", pipeline);
      if (pipeline) {
        await this.trackDealsReloadCases(pipeline.id);
      }
    });
  }

  /**
   * Get table
   * with value of deals select
   * @param {number} value dealsSelect value
   * @returns {boolean} false if selected new prospects
   * @memberof DealsComponent
   */

  public getTable(value: number) {
    if (value === 1) {
      return false;
    } else {
      return true;
    }
  }

  public minStageRule(stage: Stage): boolean {
    return this.eaClientsCondition(stage) && this.blockedStages && this.blockedStages.length > 0 && this.blockedStages.some(x => {
      if (this.minStage && this.minStage.stage_id == GlobalConstants.ALL_STAGES.NewOrder && stage.id != this.minStage.stage_id) {
        return true;
      }
      return x.id === stage.id;
    })
  }

  private eaClientsCondition(stage: Stage): boolean {
    if (this.minStage && this.currentUser) {
      return ((this.minStage.pipeline_id !== this.PIPELINES.Clients && this.minStage.pipeline_id !== this.PIPELINES.OpenOrders)
        || (this.currentUser.role_id !== RolesEnum.Clothier && this.currentUser.role_id !== RolesEnum.Admin)
      )
    }
    else if (this.currentUser && (this.currentUser.role_id === RolesEnum.EA || this.currentUser.role_id === RolesEnum.EaCaller) && this.selectedPipelineId === this.PIPELINES.ClothierContactClients) {
      return false;
    }
    return true;
  }

  public loadMore() {
    this.lazyLoading = true;
    const users = this.userStoreService.selectedUsersFilter.value;
    const pipelineId = this.pipelineStoreService.currentPipeline.value.id;
    this.dealService.getDeals(pipelineId, this.start, 20, users.map(u => u.id), this.selectedGenderId, this.filteredLocations, null,
      this.isContactByEmailOnlyFilter,
      this.isSameBuildingFilter,
      this.isWithPhoneNumberOnlyFilter,
      this.filteredCompanies, this.filteredPositions, ((pipelineId === stages.ClientsPipeline.id || pipelineId === stages.OpenOrders.id) ? this.selectedGroupId : null)).then(result => {

        if (!this.isListDeals) {
          if (result.data && result.data.length > 0) {
            this.pipelineDealsStoreService.addDeals(result.data);
            this.isMoreDeals = result.additional_data.pagination.more_items_in_collection;
            this.start = result.additional_data.pagination.next_start;
            this.lazyLoading = false;
          }
        }
      });
  }

  /**
   * Get deals
   * from API
   * @memberof DealsComponent
   */

  private async getDeals() {
    this.setupFilters();
    const currentUser = await firstValueFrom(this.userContextService.user)
    const users = await firstValueFrom(this.userStoreService.selectedUsersFilter);
    console.log("getDeals", users, currentUser);
    const usersToRequest = users && users.length > 0 && users || [currentUser];
    this.userIdsForLastGetDealsRequest = usersToRequest.map(u => u.id);
    const pipelineId = this.pipelineStoreService.currentPipeline.value.id;
    if (this.dealReqest) {
      this.dealReqest.unsubscribe();
    }
    this.dealReqest = from(this.dealService.getDeals(pipelineId, this.start, 20, this.userIdsForLastGetDealsRequest,
      this.selectedGenderId, this.filteredLocations, null, this.isContactByEmailOnlyFilter, this.isSameBuildingFilter,
      this.isWithPhoneNumberOnlyFilter, this.filteredCompanies, this.filteredPositions,
      ((pipelineId === stages.ClientsPipeline.id || pipelineId === stages.OpenOrders.id) ? this.selectedGroupId : null))).pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (!this.isListDeals) {
          this.pipelineDealsStoreService.setAdditionalData(result.additional_data);
          this.pipelineDealsStoreService.setDeals(result.data);
          this.minStage = result.additional_data.min_stage;
          this.start = result.additional_data.pagination.next_start;
          this.isMoreDeals = result.additional_data.pagination.more_items_in_collection;
          this.isShowError = false;
          this.spinner = false;
          this.lazyLoading = false;
        }
      });
  }

  private reloadDealsAfterError(error: any) {
    if (error.status === 403) {
      this.reloadDeals();
    }
    if (error.status === 409) {
      alert(this.dealService.UpdateStageConflictMessage);
      this.reloadDeals();
    }
  }

  private reloadDeals() {
    this.start = 0;
    this.getDeals();
    this.lazyLoading = true;
  }

  /**
   * Get campaign deals
   * from API
   * @memberof DealsComponent
   */
  public getCampaignDeals() {
    const users = this.userStoreService.selectedUsersFilter.value;
    const pipelineId = this.pipelineStoreService.currentPipeline.value.id;
    let usersToRequest = users && users.length > 0 && users || [this.currentUser];
    this.userIdsForLastGetDealsRequest = usersToRequest.map(u => u.id);
    this.userStoreService.setLoadedDealsUsersFilter(usersToRequest);

    this.dealService.getCampaignDeals(pipelineId, this.start, 20, this.userIdsForLastGetDealsRequest, this.selectedGenderId,
      this.filteredLocations, this.filteredCompanies, this.filteredPositions, ((pipelineId === stages.ClientsPipeline.id || pipelineId === stages.OpenOrders.id) ? this.selectedGroupId : null)).then(result => {
        if (!this.isListDeals) {
          this.pipelineDealsStoreService.setAdditionalData(result.additional_data);
          this.pipelineDealsStoreService.setDeals(result.data);
          this.minStage = result.additional_data.min_stage;
          this.start = result.additional_data.pagination.next_start;
          this.isMoreDeals = result.additional_data.pagination.more_items_in_collection;
          this.isShowError = false;
          this.spinner = false;
          this.lazyLoading = false;
        }
      });
  }

  /**
   * Update pipeline
   * change pipeline table
   * @param {any} data deals selected value
   * @memberof DealsComponent
   */

  public updatePipeline(data) {
    if (data === stages.NewProspectsPipeline.id) {
      this.spinner = true;
      this.lazyLoading = false;
      this.start = 0;
      this.router.navigate(['/pipeline', data]);
      this.resetFitlers();
    } else {
      this.spinner = true;
      this.lazyLoading = false;
      this.start = 0;
      this.router.navigate(['/pipeline', data]);
      this.resetSettings();
      this.resetFitlers();
    }
  }

  /**
   * Select item
   * navigate to person information
   * @param {any} item selected deal
   * @memberof DealsComponent
   */

  public selectItem(item: Deal) {
    window.open(window.location.origin + "/deals/" + item.id, '_blank');
  }

  /**
   * Find emails
   * find emails for deals persons
   * @param {any} item selected user
   * @memberof DealsComponent
   */


  public exportEmails() {
    this.exportEmailsModal.openModal();
  }

  public exportEmailsModalClosed() {

  }

  public exportEmailsModalSubmitted(data) {
    this.spinner = true;
    const users = this.userStoreService.selectedUsersFilter.value;
    const selectedStagesFilter = this.stageFeatureStoreService.getCurrentStages.value;

    if (data.count > 0) {
      this.dealService.exportEmails(0, data.count, null, users.map(u => u.id), this.listDeal.filterModel.filteredLocations, this.listDeal.filterModel.selectedGenderId,
        selectedStagesFilter.map(st => st.id), this.listDeal.filterModel.filteredCompanies, this.listDeal.filterModel.filteredPositions
        , this.isSameBuildingFilter
        , this.isWithPhoneNumberOnlyFilter
      ).then(result => {
        if (result) {
          CsvUtil.saveFile(
            result.map(i => ({
              FirstName: i.firstName, LastName: i.lastName,
              Email: (i.email && i.email.length > 0 && i.email.map(e => e.value).join(', ') || null), Company: i.company
            })),
            (new Date()).toISOString(), ["FirstName", "LastName", "Email", "Company"], null);
        }
        this.listDeal.start = 0;
        this.listDeal.getListDeals();
      });
    }
  }

  public exportLookups() {
    this.exportLookupsModal.openModal();
  }

  public exportLookupsModalSubmitted(data) {
    this.spinner = true;
    const users = this.userStoreService.selectedUsersFilter.value;
    const selectedStagesFilter = this.stageFeatureStoreService.getCurrentStages.value;

    if (data.count > 0 && this.listDeal) {
      this.exportService.exportDeals(selectedStagesFilter.map(s => s.id), data.count, this.listDeal.filterModel.filteredLocations, users && users.map(u => u.id) || [], this.listDeal.filterModel.filteredCompanies, this.listDeal.filterModel.filteredPositions
        , this.isSameBuildingFilter
        , this.isWithPhoneNumberOnlyFilter
      ).then(result => {
        if (result) {
          saveAs(result, (new Date()).toISOString() + ".csv");
        }
        this.listDeal.start = 0;
        this.listDeal.getListDeals();
      });
    }
  }

  public findEmails() {
    this.findEmailsModal.openModal();
  }

  public findEmailsModalClosed() {

  }

  public findEmailsModalSubmitted(data) {
    this.spinner = true;
    const users = this.userStoreService.selectedUsersFilter.value;
    const selectedStagesFilter = this.stageFeatureStoreService.getCurrentStages.value;

    if (this.listDeal) {
      this.listDeal.updateOwnerInfo = 'Task Find Emails started';
    }
    this.dealService.findEmails(data.from, data.limit, null, users.map(u => u.id), this.listDeal.filterModel.filteredLocations,
      this.listDeal.filterModel.selectedGenderId, selectedStagesFilter.map(st => st.id), this.listDeal.filterModel.filteredCompanies, this.listDeal.filterModel.filteredPositions,
      this.isSameBuildingFilter,
      this.isWithPhoneNumberOnlyFilter
    )
      .then(() => {
        this.spinner = false;
        this.listDeal.start = 0;
        this.listDeal.getListDeals();
      }),
      () => {
        this.spinner = false;
      };

    setTimeout(() => {
      if (this.listDeal) {
        this.listDeal.updateOwnerInfo = null;
      }
    }, 1500);
  }

  /**
    * Rout to new deal
    * @memberof DealsComponent
    */

  public routToNewDeal() {
    this.router.navigate(['/', 'newDeal']);
  }

  /**
    * Rout to pipeline
    * @memberof DealsComponent
    */

  public routToPipeline() {
    this.isListDeals = false;
    this.spinner = true;
    this.resetFitlers();
    const allPipelines = this.getAvaliablePipelines();
    const currentPipeline = this.pipelineStoreService.currentPipeline.value;
    if (currentPipeline && currentPipeline.id) {
      this.router.navigate(['/pipeline/', currentPipeline.id]);
      this.proceedToGetDeals();
    } else if (allPipelines && allPipelines.length > 0 && allPipelines[0].id) {
      const pipelineToRout = allPipelines[0];
      console.log('routToPipeline setCurrentPipeline');
      this.pipelineStoreService.setCurrentPipeline(pipelineToRout);
      this.router.navigate(['/pipeline/', pipelineToRout.id]);
    }
  }

  public updatePipelineData() {
    this.start = 0;
    this.spinner = true;
    this.isMasterLeadDeals = false;
  }

  resetFitlers() {
    this.isMasterLeadDeals = false;
    if (this.filters) {
      this.filters.resetFilters();
    }
  }

  /**
    * Rout to list
    * of deals
    * @memberof DealsComponent
    */

  public routToList() {
    this.resetFitlers()
    const users = this.userStoreService.selectedUsersFilter.value;
    if (!users || users.length === 0) {
      const user = this.userContextService.user.value;
      if (user && user.role_id) {
        this.userStoreService.setSelectedUsersFilter([user]);
      }
    }

    this.router.navigate(['/pipeline/', 'deals']);
    this.isMasterLeadDeals = false;
  }

  /**
   * Filter stage
   * list of deals
   * @param {any} value selected stage
   * @memberof DealsComponent
   */

  /**
   * Filter list
   * of deals by selected filters
   * @memberof DealsComponent
   */

  public filterList() {
    this.listDeal.filterList();
  }

  private resetSettings() {
    this.stylesForColumn = {};
    this.spinner = true;
    this.start = 0;
  }

  /**
    * Filter dashboard
    * @memberof DealsComponent
    */

  public async filterDashboard() {
    if (this.isListDeals) {
      this.listViewFilter();
    }
    else {
      await this.dashboardViewFilter();
    }
  }

  private setupFilters() {
    if (!this.filters) {
      return;
    }
    let filterModel: FilterDealModel = this.filters.getFilterModel();
    this.selectedGenderId = filterModel.selectedGenderId;
    this.isSameBuildingFilter = filterModel.isSameBuildingFilter;
    this.isWithPhoneNumberOnlyFilter = filterModel.isWithPhoneNumberOnlyFilter;
    this.isContactByEmailOnlyFilter = filterModel.isContactByEmailOnlyFilter;
    this.filteredCompanies = filterModel.filteredCompanies;
    this.filteredPositions = filterModel.filteredPositions;
    this.filteredLocations = filterModel.filteredLocations;
    this.selectedGroupId = filterModel.selectedGroupId;
  }

  public async dashboardViewFilter() {
    this.resetSettings();
    this.setupFilters();
    const users = this.userStoreService.selectedUsersFilter.value;
    if (!users || users.length === 0) {
      const user = this.currentUser = await firstValueFrom(this.userContextService.user);
      if (user && user.role_id) {
        this.userStoreService.setSelectedUsersFilter([user]);
      }
    }

    if (users && users.length > 0) {
      this.stageFeatureStoreService.setSelectedRole(new RoleStageFilter(RolesEnum[users[0].role_id]));
    } else {
      this.stageFeatureStoreService.setSelectedRole(new RoleStageFilter(RolesEnum[this.currentUser.role_id]));
    }

    this.pipelineStoreService.setCurrentPipeline(this.pipelineStoreService.currentPipeline.value);

    if (this.isCampaignDeals) {
      this.getCampaignDeals();
    } else if (this.isMissedMtg) {
      this.getMissedMgtDeals();
    } else {
      this.getDealsForRole();
    }
  }

  public listViewFilter() {
    let filterModel: FilterDealModel = this.filters.getFilterModel();
    this.listDeal.filterModel = filterModel;
    this.listDeal.filterList();
  }

  /**
    * Loading more deals
    * @memberof DealsComponent
    */

  public loadingMoreDeals() {
    if (this.isMasterLeadDeals) {
      this.masterLeadView.loadingMoreDeals();
    } else if (this.isListDeals) {
      this.listDeal.loadingMoreDeals();
    } else {
      this.loadMore();
    }
  }

  /**
    * Loading list deals
    * @memberof DealsComponent
    */

  public loadingListDeals(result) {
    this.isMoreDeals = result.pagination.more_items_in_collection;
    this.listDealTotalCount = result.total_count;
  }

  /**
    * Deep clone
    * coppy phone or email array
    * @param {Object[]} array selected array
    * @memberof DealsComponent
    */

  public deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

  public hideMeetingModal(model) {
    const dealsPerson = model.dealsPerson as DealDetails;
    const selectedUser = model.selectedUser as User;
    let isDashboardView = true;
    this.spinner = true
    let meetingDate = dealsPerson.meetingDateUtc;
    let meeting_date = (meetingDate) ? DateHelper.formatAsISODateString(meetingDate) : null;
    let meeting_date_utc = (meetingDate) ? meetingDate : null;
    let meeting_time = (meetingDate) ? meetingDate.toLocaleTimeString([], { hour12: false }) : null;

    this.dealService.updatePersonAddressesAndGeocode(
      dealsPerson.personId,
      dealsPerson.companyAddress,
      dealsPerson.homeAddress,
      dealsPerson.company,
      dealsPerson.location,
      dealsPerson.latitude,
      dealsPerson.longitude)
      .then(() => {
        this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null,
          this.movedDeal.previousStageId, isDashboardView, meeting_date, meeting_date_utc, meeting_time, (selectedUser) ? selectedUser.id : null).then(result => {
            if (result.is_need_to_reload) {
              this.reloadDeals()
              this.spinner = false;
            } else {

              const item = this.pipelineDealsStoreService.getPipelineDealsById(result.id);
              item.stage_id = result.stage_id;
              item.previous_labeled_stage_id = result.previous_labeled_stage_id;
              item.stage_order_nr = result.stage_order_nr;
              item.is_rotting = false;
              this.pipelineDealsStoreService.updateDeal(item);
              this.updateCounter(this.movedDeal.previousStageId, this.movedDeal.stageId);
              this.spinner = false
            }
          }, (error) => {
            this.reloadDealsAfterError(error);
          });
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  public confirmAddressModal(dealsPerson: DealDetails) {
    this.dealService.updatePersonAddressesAndGeocode(
      dealsPerson.personId,
      dealsPerson.companyAddress,
      dealsPerson.homeAddress,
      dealsPerson.company,
      dealsPerson.location,
      dealsPerson.latitude,
      dealsPerson.longitude);
  }

  public returnDeal(movedDeal) {
    const item = this.pipelineDealsStoreService.getPipelineDealsById(+movedDeal.dealId);
    item.stage_id = movedDeal.previousStageId;
    this.pipelineDealsStoreService.updateDeal(item);
  }

  public moveLeadsToUser(model) {
    let selectedUser = model.selectedUser;
    let dealsPerson = model.dealsPerson as DealDetails;
    let meetingDate = dealsPerson.meetingDateUtc;
    let isDashboardView = true;
    this.spinner = true

    this.dealService.updatePersonAddressesAndGeocode(
      dealsPerson.personId,
      dealsPerson.companyAddress,
      dealsPerson.homeAddress,
      dealsPerson.company,
      dealsPerson.location,
      dealsPerson.latitude,
      dealsPerson.longitude)
      .then(() => {
        if (meetingDate) {
          let meeting_date = DateHelper.formatAsISODateString(meetingDate);
          let meeting_date_utc = meetingDate;
          let meeting_time = meetingDate.toLocaleTimeString([], { hour12: false });
          this.dealService.moveToMeetingStage(+this.movedDeal.dealId, +this.movedDeal.previousStageId, +this.movedDeal.stageId,
            selectedUser.id, isDashboardView, meeting_date, meeting_date_utc, meeting_time).then(result => {
              if (result.is_need_to_reload) {
                this.start = 0;
                this.getDeals();
                this.spinner = true;
              } else {
                this.pipelineDealsStoreService.removeDeal(+this.movedDeal.dealId);
              }
              this.spinner = false
            }
            );
        } else {
          this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId,
            isDashboardView, null, null, null, selectedUser.id).then(result => {
              if (result.is_need_to_reload) {
                this.start = 0;
                this.getDeals();
                this.spinner = true;
              } else {
                const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
                item.stage_id = +this.movedDeal.stageId;
                item.previous_labeled_stage_id = null;
                item.user_id = selectedUser.id;
                item.genderId = this.selectedGenderId;
                item.meeting_date = null;
                item.meeting_date_utc = null;
                this.pipelineDealsStoreService.updateDeal(item);
                this.updateCounter(this.movedDeal.previousStageId, this.movedDeal.stageId);
              }
              this.spinner = false
            }, (error) => {
              this.reloadDealsAfterError(error);
            });
        }
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  private getPreviousLabeledStageId(item: any): number | null {
    let dealStagesFrom = [GlobalConstants.ALL_STAGES.DM, GlobalConstants.ALL_STAGES.SM, GlobalConstants.ALL_STAGES.Float, GlobalConstants.ALL_STAGES.SM1, GlobalConstants.ALL_STAGES.ContactClientNow]
    let dealStagesTo = [GlobalConstants.ALL_STAGES.VM1, GlobalConstants.ALL_STAGES.VM2, GlobalConstants.ALL_STAGES.VM3, GlobalConstants.ALL_STAGES.EAVMEmail1, GlobalConstants.ALL_STAGES.EAVMEmail2];
    if (!dealStagesTo.some(s => s == item.stage_id)) {
      return null;
    }
    if (dealStagesFrom.some(s => s == item.previousStageId) && dealStagesTo.some(s => s == item.stage_id)) {
      return item.previousStageId;
    }
    else {
      return item.previous_labeled_stage_id;
    }
  }

  public dragulaUpdateStage(dealId, stageId, previousStageId) {
    const currentDeal = this.pipelineDealsStoreService.getPipelineDealsById(+dealId);
    const dealsInStage = this.pipelineDealsStoreService.getPipelineDealsByStageId(+previousStageId);
    let isDashboardView = true;
    let previousLabeledStageId = currentDeal &&
      this.getPreviousLabeledStageId({ stage_id: stageId, previousStageId: previousStageId, previous_labeled_stage_id: currentDeal.previous_labeled_stage_id }) || null;
    let isLastVisibleRottenDeal = dealsInStage.filter(x => x.is_rotting === true).length === 0 && (currentDeal != null && currentDeal.is_rotting === true);
    this.dealService.updateStage(dealId, stageId, previousLabeledStageId, previousStageId, isDashboardView,
      null, null, null, null).then(result => {
        if (result.is_need_to_reload) {
          this.start = 0;
          this.getDeals();
          this.spinner = true;
        } else {
          const item = this.pipelineDealsStoreService.getPipelineDealsById(+dealId);
          item.stage_id = result.stage_id;
          item.previous_labeled_stage_id = null;
          item.stage_order_nr = result.stage_order_nr;
          item.is_rotting = false;
          item.dateOfCall = result.stage_id === GlobalConstants.ALL_STAGES.NewOrder && item.dateOfCall ? null : item.dateOfCall;
          item.called = result.stage_id === GlobalConstants.ALL_STAGES.NewOrder && item.dateOfCall ? false : item.called;
          item.personWhoReferred = result.stage_id === GlobalConstants.ALL_STAGES.NG ? "" : item.personWhoReferred;
          this.pipelineDealsStoreService.updateDeal(item);
          this.updateCounter(previousStageId, stageId);

          if (this._isEmptyStage(previousStageId) || isLastVisibleRottenDeal) { this._loadDealsToStage(previousStageId); }
        }
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  public openCustomContactsModal(deal: any): void {
    this.dialog.open(CustomContactsModalComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        deal: deal
      }
    });
  }

  private _loadDealsToStage(stageId: number) {
    const pipelineId = this.pipelineStoreService.currentPipeline.value.id;
    this.lazyLoading = true;
    this.dealService.getDeals(this.selectedPipelineId, 0, 20, this.userIdsForLastGetDealsRequest, this.selectedGenderId, this.filteredLocations, stageId,
      this.isContactByEmailOnlyFilter,
      this.isSameBuildingFilter,
      this.isWithPhoneNumberOnlyFilter,
      this.filteredCompanies, this.filteredPositions, ((pipelineId === stages.ClientsPipeline.id || pipelineId === stages.OpenOrders.id) ? this.selectedGroupId : null)).then(result => {
        this.pipelineDealsStoreService.addDeals(result.data.filter(x => x.stage_id == stageId));
        this.lazyLoading = false;
      })
  }

  private _isEmptyStage(stageId: number): boolean {
    return !this.pipelineDealsStoreService.getPipelineDealsByStageId(stageId);
  }

  private openRequiredCustomContactsModal(dealDetails: any, confirmCallback: () => void, isPhoneRequired: boolean, isEmailRequired: boolean): void {
    this.dialog.open(CustomContactsModalComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        isPhoneRequired: isPhoneRequired,
        isEmailRequired: isEmailRequired,
        closeModal: this.closeCustomContactsModal.bind(this),
        deal: dealDetails,
        confirmCallback: confirmCallback,
      }
    });
  }

  private openCustomAddressConfirmModal(dealDetails: any, meetingDateModal: any): void {
    this.dialog.open(CustomAddressConfirmComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        closeModal: this.closeDateMeetingModal.bind(this),
        saveDate: this.confirmAddressModal.bind(this),
        dealsPerson: dealDetails,
        confirmCallback: () => meetingDateModal.openMeetingDateModal(null),
      }
    });
  }

  private openCustomMeetingModal(dealDetails: any, meetingDate: Date, stageId: number): void {
    this.dialog.open(CustomMeetingComponent, {
      disableClose: true,
      maxWidth: '90vw',
      data: {
        moveLeadsToUser: this.moveLeadsToUser.bind(this),
        closeModal: this.closeDateMeetingModal.bind(this),
        saveDate: this.hideMeetingModal.bind(this),
        dealsPerson: dealDetails,
        meetingDate: meetingDate,
        stageId: stageId,
      }
    });
  }
  
  public openCustomAddressConfirmMaxDateModal(dealDetails: any, meetingDateModal: any): void {
    let maxDate = new Date(Date.now());
    maxDate.setMonth(maxDate.getMonth() + 2);
        
    this.dialog.open(CustomAddressConfirmComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        closeModal: this.closeDateMeetingModal.bind(this),
        saveDate: this.confirmAddressModal.bind(this),
        dealsPerson: dealDetails,
        confirmCallback: () => meetingDateModal.openMeetingDateModal(null, maxDate),
      }
    });
  }    
        
  public openCustomMeetingConfirmModal(dealDetails: any, meetingDate: Date): void {
    this.dialog.open(CustomMeetingConfirmComponent, {
      disableClose: true,
      maxWidth: '90vw',
      data: {
        closeModal: this.closeDateMeetingModal.bind(this),
        saveDate: this.hideMeetingModal.bind(this),
        dealsPerson: dealDetails,
        meetingDate: meetingDate,
      }
    });
  }
          
  public getMissedMgtDeals() {
    const pipelineId = this.pipelineStoreService.currentPipeline.value.id;
    const users = this.userStoreService.selectedUsersFilter.value;
    let usersToRequest = users && users.length > 0 && users || [this.currentUser];
    this.userIdsForLastGetDealsRequest = usersToRequest.map(u => u.id);

    this.dealService.getMissedMtgDeals(pipelineId, this.userIdsForLastGetDealsRequest, this.start, 20, this.selectedGenderId,
      this.filteredLocations, this.filteredCompanies, this.filteredPositions,
      ((pipelineId === stages.ClientsPipeline.id || pipelineId === stages.OpenOrders.id) ? this.selectedGroupId : null)).then(result => {
        if (!this.isListDeals) {
          this.pipelineDealsStoreService.setAdditionalData(result.additional_data);
          this.pipelineDealsStoreService.setDeals(result.data);
          this.minStage = result.additional_data.min_stage;
          this.start = result.additional_data.pagination.next_start;
          this.isMoreDeals = result.additional_data.pagination.more_items_in_collection;
          this.isShowError = false;
          this.spinner = false;
          this.lazyLoading = false;
        }
      });
  }

  public openBasketClientCardModal(deal: Deal) {
    this.basketClientCardDeal = deal;
    this.dialog.open(BasketClientCardModalComponent, {
      disableClose: true,
      width: "100vw",
      maxWidth: "100vw",
      data: {
        deal: this.basketClientCardDeal,
        onClose: this.closeBasketClientCardModal.bind(this)
      }
    })
  }

  public closeBasketClientCardModal() {
    this.basketClientCardDeal = null;
  }

  public closeReferralModal() {
    this.returnDeal(this.movedDeal);
  }

  public closeDateMeetingModal() {
    this.returnDeal(this.movedDeal);
  }

  public closeCustomDateModal() {
    this.returnDeal(this.movedDeal);
  }

  public closeExportModal() {
    this.listDeal.start = 0;
    this.listDeal.getListDeals();
  }

  public closeCustomContactsModal() {
    this.returnDeal(this.movedDeal);
  }

  public saveDateOfMeeting(date) {
    let meeting_date = DateHelper.formatAsISODateString(date);
    let meeting_date_utc = DateHelper.formatAsISODateString(date);
    let meeting_time = date.toLocaleTimeString([], { hour12: false });
    this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId, this.isDashboard,
      meeting_date, meeting_date_utc, meeting_time, null).then(result => {
        if (result.is_need_to_reload) {
          this.reloadDeals()
        } else {
          const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
          item.stage_id = +this.movedDeal.stageId;
          item.previous_labeled_stage_id = null;
          item.stage_order_nr = result.stage_order_nr;
          item.is_rotting = false;
          this.pipelineDealsStoreService.updateDeal(item);
          this.updateCounter(this.movedDeal.previousStageId, this.movedDeal.stageId);
        }
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  public saveCustomDate(event) {
    this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId, this.isDashboard,
      null, null, null, null, event.date, event.isNrContactedRepeat).then(result => {
        if (result.is_need_to_reload) {
          this.reloadDeals()
        } else {
          const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
          item.stage_id = +this.movedDeal.stageId;
          item.previous_labeled_stage_id = result.previous_labeled_stage_id;
          item.stage_order_nr = result.stage_order_nr;
          item.is_rotting = false;
          this.pipelineDealsStoreService.updateDeal(item);
          this.updateCounter(this.movedDeal.previousStageId, this.movedDeal.stageId);
        }
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  private updateCounter(previousStageId: number, stageId: number) {
    let currentStage = this.dealsCountByStage[previousStageId];
    if (Number.isNaN(currentStage) || !currentStage) {
      currentStage = 0;
    }
    let newStage = this.dealsCountByStage[stageId];
    if (Number.isNaN(newStage) || !newStage) {
      newStage = 0;
    }
    this.dealsCountByStage[previousStageId] = currentStage == 0 ? 0 : currentStage - 1;
    this.dealsCountByStage[stageId] = newStage + 1;
  }

  public saveWhoReferred(whoReferredText) {
    this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId, this.isDashboard,
      null, null, null, null).then(result => {
        if (result.is_need_to_reload) {
          this.reloadDeals();
        } else {
          const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
          item.stage_id = +this.movedDeal.stageId;
          item.previous_labeled_stage_id = result.previous_labeled_stage_id;
          item.stage_order_nr = result.stage_order_nr;
          item.is_rotting = false;
          item.personWhoReferred = whoReferredText;
          this.pipelineDealsStoreService.updateDeal(item);
          this.updateCounter(this.movedDeal.previousStageId, this.movedDeal.stageId);
        }
        this.dealService.updatePersonWhoReferred(this.movedDeal.dealId, whoReferredText);
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  public exportFile() {
    const selectedUsers = this.userStoreService.selectedUsersFilter.value;
    if (this.currentUser.role_id === RolesEnum.DirectMailAccount || (selectedUsers.length > 0 && selectedUsers[0].role_id === RolesEnum.DirectMailAccount)) {
      this.exportFileModal.maxCountDealsForExport = this.pipelineDealsStoreService.additionalData.value.total_count;
    }

    this.exportFileModal.openExportModal();
  }

  public exportDeals() {
    const users = this.userStoreService.selectedUsersFilter.value;
    const selectedStagesFilter = this.stageFeatureStoreService.getCurrentStages.value;

    this.exportFileModal.setExportData(this.listDeal.filterModel.filteredLocations, selectedStagesFilter.map(st => st.id), users.map(u => u.id), this.listDeal.filterModel.filteredCompanies, this.listDeal.filterModel.filteredPositions
      , this.isSameBuildingFilter
      , this.isWithPhoneNumberOnlyFilter
    );
  }

  public cancelMoveRecord() {
    this.returnDeal(this.movedDeal);
    this.killRecordModal.inputData = "";
  }

  public onTrackingLinkCanceled() {
    if (this.orderShippedChangedDeal) {
      this.orderShippedChangedDeal.isOrderShipped = false;
      this.orderShippedChangedDeal = null;
    }
    else {
      this.returnDeal(this.movedDeal);
    }
    this.trackingLinkModal.inputData = "";
  }

  public async onTrackingLinkConfirmed() {
    if (this.orderShippedChangedDeal) {
      this.orderShippedChangedDeal.isOrderShipped = true;
      await this.dealService.updateTrackingLink({ dealId: this.orderShippedChangedDeal.id, trackingLink: this.trackingLinkModal.inputData });
      await this.dealService.updateIsOrderShipped(this.orderShippedChangedDeal.id, this.orderShippedChangedDeal.isOrderShipped);

      this.trackingLinkModal.inputData = "";
      if (
        this.orderShippedChangedDeal.stage_id === this.ALL_STAGES.Less3Weeks
        || this.orderShippedChangedDeal.stage_id === this.ALL_STAGES.Less6Weeks
        || this.orderShippedChangedDeal.stage_id === this.ALL_STAGES.Less9Weeks
        || this.orderShippedChangedDeal.stage_id === this.ALL_STAGES.OverdueLess12Weeks
      ) {
        this.orderShippedUpdateStage(this.orderShippedChangedDeal);
      }
      this.orderShippedChangedDeal = null;
    }
    else {
      const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
      item.stage_id = +this.movedDeal.stageId;
      this.pipelineDealsStoreService.updateDeal(item);
      let isDashboardView = true;
      this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId, isDashboardView,
        null, null, null, null).then(async () => {
          await this.dealService.updateTrackingLink({ dealId: this.movedDeal.dealId, trackingLink: this.trackingLinkModal.inputData });
          this.trackingLinkModal.inputData = "";
          this.start = 0;
          this.spinner = true;
          this.getDeals();
        }, (error) => {
          this.reloadDealsAfterError(error);
        });
    }
  }

  public moveRecordToKill() {
    const item = this.pipelineDealsStoreService.getPipelineDealsById(+this.movedDeal.dealId);
    item.stage_id = +this.movedDeal.stageId;
    this.pipelineDealsStoreService.updateDeal(item);
    let isDashboardView = true;
    this.dealService.updateStage(this.movedDeal.dealId, this.movedDeal.stageId, null, this.movedDeal.previousStageId, isDashboardView,
      null, null, null, null).then(() => {
        this.noteService.AddReasonOfKill(this.movedDeal.dealId, this.killRecordModal.inputData).then(() => {
          this.killRecordModal.inputData = "";
          this.start = 0;
          this.spinner = true;
          this.getDeals();
        })
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
  }

  public reselectRottingTimeReschedule(deal: Deal) {
    this.movedDeal = { dealId: deal.id, stageId: deal.stage_id, previousStageId: deal.stage_id };
    this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.RescheduleOptions);
  }

  public reselectRottingTimeInAlteration(deal: Deal) {
    this.movedDeal = { dealId: deal.id, stageId: deal.stage_id, previousStageId: deal.stage_id };
    this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.InAlterationsOptions);
  }

  public reselectRottingTimeDeliveryMeeting(deal: Deal) {
    this.movedDeal = { dealId: deal.id, stageId: deal.stage_id, previousStageId: deal.stage_id };
    let meetingDateModal = this.meetingDateModal;
    let maxDate = new Date(new Date().setDate((new Date()).getDate() + 5 * 7));

    this.dialog.open(CustomAddressConfirmComponent, {
      disableClose: true,
      maxWidth: "100vw",
      data: {
        closeModal: this.closeDateMeetingModal.bind(this),
        saveDate: this.confirmAddressModal.bind(this),
        dealsPerson: deal,
        confirmCallback: function () { meetingDateModal.openMeetingDateModal(null, maxDate); },
      }
    });
  }

  public closeMeetingModal() {
    this.returnDeal(this.movedDeal)
  }

  public uploadWardrobePlanModal(deal: Deal) {
    this.uploadWardrobePlan.openUploadModal(deal);
  }

  public exportWardrobePlan(deal: Deal) {
    const id = deal.id;
    const title = deal.title;
    this.wardrobePlan.printPdf(id, title);
  }

  public openMeetingPrepPdf(deal: Deal) {
    this.meetingPrep.openMeetingPrepPdf(deal);
  }

  public onIsOrderShippedChanged(deal: Deal) {
    this.orderShippedChangedDeal = deal;
    this.trackingLinkModal.openModal(null);
  }

  public onIsOpenOrderNotifiedChanged(deal: Deal) {
    this.openOrderNotifiedChangedDeal = deal;
    const main = this;
    this.eaFollowUpImageUpload.openUploadModal(() => {
      if (main.openOrderNotifiedChangedDeal) {
        main.dealService.updateIsOpenOrderNotified(main.openOrderNotifiedChangedDeal.id, main.openOrderNotifiedChangedDeal.isOpenOrderNotified).then((result) => {
          if (result) {
            main.openOrderNotifiedChangedDeal.is_rotting = false;
            main.openOrderNotifiedChangedDeal = null;
          }
        });
      }
    }, () => {
      if (main.openOrderNotifiedChangedDeal) {
        main.openOrderNotifiedChangedDeal.isOpenOrderNotified = false;
        main.openOrderNotifiedChangedDeal = null;
      }
    }, deal.id, deal.stage_id);
  }

  public onIsDeliveryEmailSentChanged(deal: Deal) {
    this.isDeliveryEmailSentChangedDeal = deal;
    const main = this;
    this.eaFollowUpImageUpload.openUploadModal(() => {
      if (main.isDeliveryEmailSentChangedDeal) {
        main.dealService.updateIsDeliveryEmailSent(main.isDeliveryEmailSentChangedDeal.id, main.isDeliveryEmailSentChangedDeal.isDeliveryEmailSent).then((result) => {
          if (result) {
            main.isDeliveryEmailSentChangedDeal.is_rotting = false;
            main.isDeliveryEmailSentChangedDeal = null;
          }
        });
      }
    }, () => {
      if (main.isDeliveryEmailSentChangedDeal) {
        main.isDeliveryEmailSentChangedDeal.isDeliveryEmailSent = false;
        main.isDeliveryEmailSentChangedDeal = null;
      }
    }, deal.id, deal.stage_id);
  }

  public orderShippedUpdateStage(deal: Deal) {
    this.dragulaUpdateStage(deal.id, GlobalConstants.ALL_STAGES.OrderShipped, deal.stage_id)
  }

  public draftReadyUpdateStage(deal: Deal) {
    this.dragulaUpdateStage(deal.id, GlobalConstants.ALL_STAGES.DraftOrder, deal.stage_id)
  }

  public increaseRottingTimeChange(deal: Deal) {
    if (!deal.increasingRottenTimeCounter || !deal.maxIncreasingRottenTimeCounter
      || (deal.increasingRottenTimeCounter < deal.maxIncreasingRottenTimeCounter)) {

      this.dealService.increaseRottingTime(deal.id).then(result => {
        this.reloadDeals()
      }, (error) => {
        this.reloadDealsAfterError(error);
      });
    }
  }

  public get isAllowGlobalChat(): boolean {
    return this.currentUser
      && (this.currentUser.role_id === RolesEnum.MasterLead
        || this.currentUser.role_id === RolesEnum.Admin
        || this.currentUser.role_id === RolesEnum.Recruiter
        || this.currentUser.role_id === RolesEnum.Clothier);
  }

  private setRole() {
    this.userStoreService.selectedUsersFilter.pipe(take(1)).subscribe(users => {
      if (users && users.length > 0) {
        this.stageFeatureStoreService.setSelectedRole(new RoleStageFilter(RolesEnum[users[0].role_id]));
      } else {
        this.stageFeatureStoreService.setSelectedRole(new RoleStageFilter(RolesEnum[this.currentUser.role_id]));
      }
    });
  }
}
