"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var InsertDataQuill = function () {
  function InsertDataQuill(type, value) {
    this.type = type;
    this.value = value;
  }
  return InsertDataQuill;
}();
exports.InsertDataQuill = InsertDataQuill;
var InsertDataCustom = function () {
  function InsertDataCustom(type, value) {
    this.type = type;
    this.value = value;
  }
  return InsertDataCustom;
}();
exports.InsertDataCustom = InsertDataCustom;