import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClientRating } from 'src/app/core/enums/ClientRating';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { MessageTemplateVariableDescriptionViewModel } from 'src/app/core/models/message-template/MessageTemplateVariableDescriptionViewModel';
import { TemplateVariableTypes } from 'src/app/core/enums/TemplateVariableTypes';
import { BulkMessageFacadeService } from 'src/app/core/services/bulk-message/bulk-message-facade.service';
import { environment } from 'src/environments/environment';
import { BulkMessageEmailChipsFieldComponent } from './bulk-message-email-chips-field/bulk-message-email-chips-field.component';
import { BulkMessageRequestApiService } from 'src/app/core/services/bulk-message/bulk-message-request-api.service';

@Component({
  selector: 'pd-bulk-message-email',
  templateUrl: './bulk-message-email.component.html',
  styleUrls: ['./bulk-message-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageEmailComponent implements OnInit {
  @ViewChild('emailsCcField') public emailsCcField: BulkMessageEmailChipsFieldComponent;
  @ViewChild('emailsBccField') public emailsBccField: BulkMessageEmailChipsFieldComponent;

  @Input() public isTemplatePreview: boolean = false;

  public currentTemplate: MessageTemplateViewModel;
  public TemplateVariableType = TemplateVariableContentTypes;
  public spinner: boolean = false;
  public copied: boolean = false;
  public sent: boolean = false;
  public errorSent: boolean = false;
  public isNeedUnsubscribeHtml: boolean = true;
  public emailSubject: string;
  public emailBody: string;
  public messageFromEmail: string = environment.defMessageFromEmail;
  public messageFromName: string = environment.defMessageFromName;
  public previewEmailTo: string;
  public contentPlaceholderInline: string;
  public delta: any = '';

  public variableDescriptions: MessageTemplateVariableDescriptionViewModel[] = [];
  public subjectVariables: MessageTemplateVariableDescriptionViewModel[] = [];
  public variableTypes = this.getVariableTypes();
  public previewDealId: number = environment.previewDealId;
  public previewDealInfo: any;

  public editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['link', 'image'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ]
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private bulkMessageRequestApiService: BulkMessageRequestApiService,
    private bulkMessageFacadeService: BulkMessageFacadeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner = true;
    await this.loadSimpleDealInfo();
    this.spinner = false;
  }

  private getVariableTypes() {
    return Object.keys(TemplateVariableContentTypes)
      .filter(key => !isNaN(Number(TemplateVariableContentTypes[key])))
      .filter(key => key !== 'Static Text')
      .map(key => ({
        id: TemplateVariableContentTypes[key],
        name: key
      }));
  }

  private async loadSimpleDealInfo() {
    this.previewDealInfo = await this.bulkMessageRequestApiService.getSimpleDealInfo(this.previewDealId);
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public generateVariables() {
    this.variableDescriptions = this.generateTemplateVariables(this.emailBody, TemplateVariableTypes.Body);
  }

  public generateSubjectVariables() {
    this.subjectVariables = this.generateTemplateVariables(this.emailSubject, TemplateVariableTypes.Subject);
  }

  private generateTemplateVariables(text: string, type: TemplateVariableTypes): MessageTemplateVariableDescriptionViewModel[] {
    if (!text) return [];

    const count = this.getPlaceholdersCount(text);
    const variables: MessageTemplateVariableDescriptionViewModel[] = Array.from({ length: count }, (_, i) => {
      const variableNumber = i + 1;

      const existingVariable = this[type === TemplateVariableTypes.Body ? 'variableDescriptions' : 'subjectVariables']
        .find(variable => variable.variableNumber === variableNumber);

      if (existingVariable) {
        return existingVariable;
      } else {
        const newVariable = new MessageTemplateVariableDescriptionViewModel();
        newVariable.variableNumber = variableNumber;
        newVariable.templateVariableType = type;
        newVariable.templateVariableContentType = TemplateVariableContentTypes['Static Text'];
        newVariable.exampleData = '';
        newVariable.messageTemplateId = this.currentTemplate?.id || 0;
        return newVariable;
      }
    });

    return variables;
  }

  public updateExampleData(variable) {
    if (this.previewDealInfo) {
      const contentType = variable.templateVariableContentType;
      variable.exampleData = contentType === TemplateVariableContentTypes['Client First Name']
        ? this.previewDealInfo.firstName
        : contentType === TemplateVariableContentTypes['Owner Name']
          ? this.previewDealInfo.ownerName
          : '';
    }
  }

  public onContentChanged(event: any) {
    this.delta = event.content;
    this.generateVariables();
    this.generateInlinePlaceholder();
  }

  public generateInlinePlaceholder() {
    if (!this.delta) {
      this.contentPlaceholderInline = '';
      return;
    }

    const deltaOps = this.delta.ops || this.delta;
    const converter = new QuillDeltaToHtmlConverter(deltaOps, { inlineStyles: true });
    this.contentPlaceholderInline = converter.convert();
  }

  public async sendPreviewEmail() {
    const data = this.composeEmailData();
    const result = await this.bulkMessageRequestApiService.sendPreviewEmail(data);

    this[result ? 'sent' : 'errorSent'] = true;

    setTimeout(() => {
      this[result ? 'sent' : 'errorSent'] = false;
    }, 5000);
  }

  private composeEmailData() {
    return {
      messageFrom: this.messageFromEmail,
      messageFromName: this.messageFromName,
      to: this.previewEmailTo,
      html: this.getBodyWithFilledPlaceholders(),
      subject: this.getSubjectWithFilledPlaceholders(),
      isNeedUnsubscribeHtml: this.isNeedUnsubscribeHtml
    };
  }

  private getPlaceholdersCount(text: string): number {
    const matches = text.match(/{{\d+}}/g);
    return matches ? matches.length : 0;
  }

  private getSubjectWithFilledPlaceholders(): string {
    return this.fillPlaceholders(this.emailSubject, this.subjectVariables);
  }

  private getBodyWithFilledPlaceholders(): string {
    return this.fillPlaceholders(this.emailBody, this.variableDescriptions);
  }

  private fillPlaceholders(text: string, variables: MessageTemplateVariableDescriptionViewModel[]): string {
    if (!text) return '';

    let result = text;
    variables.forEach(varItem => {
      const placeholder = `{{${varItem.variableNumber}}}`;
      result = result.replace(new RegExp(this.escapeRegExp(placeholder), 'g'), varItem.exampleData);
    });

    return result;
  }

  public getData() {
    const allVariables = [...this.variableDescriptions, ...this.subjectVariables];
    return {
      messageTemplateId: null,
      from: this.messageFromEmail,
      fromName: this.messageFromName,
      emailsCc: this.emailsCcField.getEmails()?.join(';'),
      emailsBcc: this.emailsBccField.getEmails()?.join(';'),
      body: this.contentPlaceholderInline,
      subject: this.emailSubject,
      variableDescriptions: allVariables,
      isNeedUnsubscribeHtml: this.isNeedUnsubscribeHtml
    };
  }

  public setData(data: MessageTemplateViewModel) {
    this.emailSubject = data.subject;
    this.emailBody = data.body;
    this.messageFromEmail = data.from;
    this.messageFromName = data.fromName;
    this.emailsCcField.setEmails(this.parseEmails(data.emailsCc));
    this.emailsBccField.setEmails(this.parseEmails(data.emailsBcc));
    this.subjectVariables = data.variableDescriptions.filter(v => v.templateVariableType === TemplateVariableTypes.Subject);
    this.variableDescriptions = data.variableDescriptions.filter(v => v.templateVariableType === TemplateVariableTypes.Body);
    this.currentTemplate = data;
    this.isNeedUnsubscribeHtml = data.isNeedUnsubscribeHtml;
    this.generateSubjectVariables();
    this.generateInlinePlaceholder();
    this.cdRef.detectChanges();
  }

  public sendPreviewValidation(): boolean {
    return this.previewDealInfo && this.previewEmailTo && this.validation();
  }

  public validation(): boolean {
    return !this.spinner
      && this.emailSubject
      && this.emailBody
      && this.messageFromEmail
      && this.messageFromName
      && this.allVariablesHaveExampleData()
      && this.isUniqueCombination()
      && this.arePlaceholdersValid(this.emailSubject)
      && this.arePlaceholdersValid(this.emailBody);
  }

  private allVariablesHaveExampleData(): boolean {
    return [...this.subjectVariables, ...this.variableDescriptions].every(v => v.exampleData);
  }

  private isUniqueCombination(): boolean {
    const combinationSet = new Set<string>();
    const variables = [...this.variableDescriptions, ...this.subjectVariables];

    for (const variable of variables) {
      const combination = `${variable.variableNumber}-${variable.templateVariableType}`;
      if (combinationSet.has(combination)) {
        return false;
      }
      combinationSet.add(combination);
    }
    return true;
  }

  private arePlaceholdersValid(text: string): boolean {
    const placeholders = Array.from(text.matchAll(/\{\{(\d+)\}\}/g)).map(match => parseInt(match[1], 10));
    return placeholders.every((placeholder, index) => placeholder === index + 1);
  }

  public onCopy() {
    this.copied = true;

    setTimeout(() => {
      this.copied = false;
    }, 2000);

    this.bulkMessageFacadeService.setCopiedTemplateSetting(this.currentTemplate);
  }

  public parseEmails(emails: string): string[] {
    return emails ? emails.split(';').map(email => email.trim()) : [];
  }
}