import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BulkMessageRequestViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestViewModel';
import { BulkMessageSettingViewModel } from 'src/app/core/models/bulk-message/BulkMessageSettingsViewModel';
import { RequestStatus } from '../../core/enums/RequestStatus';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { TemplateVariableTypes } from 'src/app/core/enums/TemplateVariableTypes';
import { ClientRating } from '../../core/enums/ClientRating';
import { BulkMessageEmailComponent } from '../bulk-message-setting/bulk-message-email/bulk-message-email.component';

@Component({
  selector: 'pd-bulk-message-table',
  templateUrl: './bulk-message-table.component.html',
  styleUrls: ['./bulk-message-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkMessageTableComponent {
  @ViewChild('fullscreenView') fullscreenView: ElementRef;
  @ViewChild('templateView') templateView: BulkMessageEmailComponent;

  @Input() isMain: boolean;
  @Input() isArchive: boolean;
  @Input() isSelectedItem: boolean;
  @Input() title: string;
  @Input() settings: Array<BulkMessageSettingViewModel>;

  @Output() onSelectRequest = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onArchive = new EventEmitter<any>();
  @Output() onConfigureMessage = new EventEmitter<any>();

  RequestStatus = RequestStatus;
  MessageTemplateType = MessageTemplateType;
  TemplateVariableTypes = TemplateVariableTypes;
  ClientRating = ClientRating;

  removeSetting(settingId: string) {
    this.onRemove.emit({ settingId });
  }

  archiveSetting(settingId: string, value: boolean) {
    this.onArchive.emit({ settingId, value });
  }

  onRequestSelectChange(event: any) {
    this.onSelectRequest.emit(event);
  }

  onShowTemplate(request: BulkMessageRequestViewModel) {
    this.templateView.setData(request.messageTemplate);
    this.fullscreenView.nativeElement.style.display = 'block';
  }

  closeTemplateView() {
    this.fullscreenView.nativeElement.style.display = 'none';
  }

  configureMessage(event: any) {
    this.onConfigureMessage.emit(event);
  }
}