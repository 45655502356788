import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ClientCounterSettingsComponent } from './client-counter-settings/client-counter-settings.component';
import { AuthGuard } from './core/guards/autuh.guard';
import { GlobalConstants } from './core/global-constants';
import { PaymentComponent } from './payment/payment.component';
import { UploadingComponent } from './uploading/uploading.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { HotlistComponent } from './hotlist/hotlist.component';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './users/new-user/new-user.component';
import { TasksComponent } from './tasks/tasks.component';
import { SearchNumbersTaskComponent } from './tasks/search-numbers-task/search-numbers-task.component';
import { SearchGenderTaskComponent } from './tasks/search-gender-task/search-gender-task.component';
import { TargetedRecallTaskComponent } from './tasks/targeted-recall-task/targeted-recall-task.component';
import { ImportDealsTaskComponent } from './tasks/import-deals-task/import-deals-task.component';
import { ModelerFabricUploadComponent } from './tasks/3d-modeler-fabric-upload/3d-modeler-fabric-upload.component';
import { SearchAddressTaskComponent } from './tasks/search-address-task/search-address-task.component';
import { WhatsappChatComponent } from './chat/chat.component';
import { ClothierToEaConnectionComponent } from './clothier-to-ea-connection/clothier-to-ea-connection.component';
import { NearbyCompaniesComponent } from './tasks/nearby-companies/nearby-companies.component';
import { DealsComponent } from './deals/deals.component';
import { ListDealComponent } from './deals/list-deal/list-deal.component';
import { MasterLeadComponent } from './deals/master-lead/master-lead.component';
import { NewDealComponent } from './deals/new-deal/new-deal.component';
import { GeocodeNearbyDealsComponent } from './geocode-nearby-deals/geocode-nearby-deals.component';
import { DealsDetailsComponent } from './deals/deals-details/deals-details.component';
import { AvatarSeasonalProposalComponent } from './avatar-seasonal-proposal/avatar-seasonal-proposal.component';
import { AchievementsComponent } from './achievements-card/achievements.component';
import { Modeler3dProductComponent } from './3d-modeler-product/3d-modeler-product.component';
import { UserRequestPanelComponent } from './user-request-panel/user-request-panel.component';
import { HotlistDownloadComponent } from './hotlist-download/hotlist-download.component';
import { ReportsComponent } from './tasks/reports/reports.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { SocialMediaAdsTokensListComponent } from './tasks/social-media-ads-tokens-list/social-media-ads-tokens-list.component';
import { BulkMessageSettingsComponent } from './bulk-message-settings/bulk-message-settings.component';
import { BulkMessageSettingComponent } from './bulk-message-settings/bulk-message-setting/bulk-message-setting.component';

const ROUTES: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'pipeline/:id', component: DealsComponent, canActivate: [AuthGuard], children: [
      { path: 'deals', component: ListDealComponent },
      { path: 'masterLeadDeals', component: MasterLeadComponent }
    ]
  },
  { path: 'deals/:id', component: DealsDetailsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'newDeal', component: NewDealComponent, canActivate: [AuthGuard] },
  { path: 'newUser', component: NewUserComponent, canActivate: [AuthGuard] },
  {
    path: GlobalConstants.ROUTE_NAMES.Tasks, component: TasksComponent, canActivate: [AuthGuard], children: [
      { path: GlobalConstants.ROUTE_NAMES.SearchNumbersTask, component: SearchNumbersTaskComponent },
      { path: GlobalConstants.ROUTE_NAMES.SearchGenderTask, component: SearchGenderTaskComponent },
      { path: GlobalConstants.ROUTE_NAMES.TargetedRecallTask, component: TargetedRecallTaskComponent },
      { path: GlobalConstants.ROUTE_NAMES.ImportDealsTask, component: ImportDealsTaskComponent },
      { path: GlobalConstants.ROUTE_NAMES.AddFabricsTask, component: ModelerFabricUploadComponent },
      { path: GlobalConstants.ROUTE_NAMES.SearchAddressTask, component: SearchAddressTaskComponent },
    ]
  },
  { path: GlobalConstants.ROUTE_NAMES.Reports, component: ReportsComponent, canActivate: [AuthGuard], children: [] },
  { path: 'Users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'GlobalSearch', component: GlobalSearchComponent, canActivate: [AuthGuard] },
  { path: 'NearbyCompanies', component: NearbyCompaniesComponent, canActivate: [AuthGuard] },
  { path: 'Map', component: GeocodeNearbyDealsComponent, canActivate: [AuthGuard] },
  { path: 'ProductCards', component: Modeler3dProductComponent, canActivate: [AuthGuard] },
  { path: 'Hotlist/:token', component: HotlistComponent },
  { path: 'Hotlist/:id/:name', component: HotlistComponent, canActivate: [AuthGuard] },
  { path: 'hotlist/get-pdf-landing/:id', component: HotlistDownloadComponent, canActivate: [AuthGuard] },
  { path: 'Uploading', component: UploadingComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.Payment, component: PaymentComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.PaymentResult, component: PaymentResultComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.ClientSettings, component: ClientCounterSettingsComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.BulkMessageSettings, component: BulkMessageSettingsComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.BulkMessageSetting, component: BulkMessageSettingComponent, canActivate: [AuthGuard] },
  { path: 'ClothiersRequests', component: UserRequestPanelComponent, canActivate: [AuthGuard] },
  { path: 'ClothierToEaConnection', component: ClothierToEaConnectionComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.GlobalChat, component: WhatsappChatComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.AvatarSeasonalProposal, component: AvatarSeasonalProposalComponent, canActivate: [AuthGuard] },
  { path: 'Achievements', component: AchievementsComponent, canActivate: [AuthGuard] },
  { path: GlobalConstants.ROUTE_NAMES.SocialMediaAdsTokens, component: SocialMediaAdsTokensListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
