import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClientRating } from '../../../core/enums/ClientRating';
import { RequestStatus } from '../../../core/enums/RequestStatus';
import { EnumUtil } from '../../../core/utils/enum.util';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { BulkMessageSettingViewModel } from 'src/app/core/models/bulk-message/BulkMessageSettingsViewModel';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { TemplateVariableTypes } from 'src/app/core/enums/TemplateVariableTypes';
import * as stages from '../../../../assets/stages_new_prospespects';
import { BulkMessageRequestViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestViewModel';

@Component({
  selector: 'pd-bulk-message-table-row',
  templateUrl: './bulk-message-table-row.component.html',
  styleUrls: ['./bulk-message-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageTableRowComponent implements OnInit {
  @Input() setting: BulkMessageSettingViewModel;
  @Input() isMain: boolean;
  @Input() isArchive: boolean;
  @Input() isSelectedItem: boolean;

  @Output() onRequestSelectChange = new EventEmitter<any>();
  @Output() onShowTemplate = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onArchive = new EventEmitter<any>();
  @Output() onConfigureMessage = new EventEmitter<any>();

  RequestStatus = RequestStatus;
  MessageTemplateType = MessageTemplateType;
  TemplateVariableTypes = TemplateVariableTypes;
  EnumUtil = EnumUtil;

  selectedRequest: BulkMessageRequestViewModel;

  constructor(private cdRef: ChangeDetectorRef) { }

  async ngOnInit() {
  }

  public requestSelectChange(requestId: number) {
    this.selectedRequest = this.setting.requests.find(r => r.id === requestId);
    this.onRequestSelectChange.emit(this.selectedRequest);
    this.cdRef.detectChanges();
  }

  select() {
    window.open(window.location.origin + "/bulk-message-setting/" + this.setting.id, '_blank');
  }

  remove(settingId: number) {
    this.onRemove.emit({ settingId: settingId });
  }

  archive(settingId: number, value: boolean) {
    this.onArchive.emit({ settingId: settingId, value: value });
  }

  showTemplate(request: BulkMessageRequestViewModel) {
    this.onShowTemplate.emit(request);
  }

  configureWhatsappMessageSending(event: any) {
    const data = {
      request: event,
      isEmail: false,
      isWhatsApp: true
    };
    this.onConfigureMessage.emit(data);
  }

  configureEmailMessageSending(event: any) {
    const data = {
      request: event,
      isEmail: true,
      isWhatsApp: false
    };
    this.onConfigureMessage.emit(data);
  }

  parseSettingData() {
    const ownersOfDealsStr = (this.setting.owners && this.setting.owners.length > 0)
      ? this.setting.owners.map(r => r.name).join('<br/>')
      : 'All';

    const ratingsStr = (this.setting.clientRatings && this.setting.clientRatings.length > 0)
      ? this.setting.clientRatings.sort().map(r => this.getRatingLabel(r)).join('<br/>')
      : 'All';

    const pipelinesStr = this.setting.pipelines.map(r => this.getPipelineLabel(r)).join('<br/>');

    return `<div><b>Pipelines: </b><br/>${pipelinesStr}</div><br/>
            <div><b>Client Ratings: </b><br/>${ratingsStr}</div><br/>
            <div><b>Owners Of Deals: </b><br/>${ownersOfDealsStr}</div><br/>`;
  }

  parseRequestData() {
    const replyRecipientsStr = (this.selectedRequest.replyRecipients && this.selectedRequest.replyRecipients.length > 0)
      ? this.selectedRequest.replyRecipients.map(r => r.name).join('<br/>')
      : 'Nobody';

    return `<div><b>Reply Recipients: </b><br/>${replyRecipientsStr}</div><br/>`;
  }

  getTemplateName() {
    if (this.selectedRequest?.messageTemplate?.messageTemplateType == MessageTemplateType.Email) {
      return this.selectedRequest?.messageTemplate?.subject ?? 'Unknown';
    }

    return this.selectedRequest?.messageTemplate?.displayName ?? 'Unknown';
  }

  getTemplateBody() {
    return this.selectedRequest?.messageTemplate?.body ?? 'Unknown';
  }

  getTemplateVariablesHtml(templateVariableType: TemplateVariableTypes): string {
    if (!this.selectedRequest) {
      return '';
    }

    const requestVariables = this.selectedRequest.variables;
    const templateVariables = this.selectedRequest.messageTemplate?.variableDescriptions.filter(v => v.templateVariableType == templateVariableType);
    const htmlStrings: string[] = [];

    if (!(requestVariables && templateVariables)) {
      return '';
    }

    templateVariables.forEach(templateVar => {
      const requestVar = requestVariables.find(rv => rv.variableDescriptionId === templateVar.id);
      const variableNumber = templateVar.variableNumber;
      let displayValue: string;

      if (templateVar.templateVariableContentType === TemplateVariableContentTypes["Static Text"]) {
        displayValue = requestVar?.staticValue;
        const html = `<div><b>{{${variableNumber}}}</b> - ${displayValue}</div>`;
        htmlStrings.push(html);
      } else {
        displayValue = TemplateVariableContentTypes[templateVar.templateVariableContentType];
        const html = `<div><b>{{${variableNumber}}}</b> - <i>${displayValue}</i></div>`;
        htmlStrings.push(html);
      }
    });

    return htmlStrings.join('');
  }

  getLocationBody() {
    return this.setting.locations;
  }

  getSelectedStatusesMessage(request: any): string {
    let receivedStatus = '';
    if (request.isSendToReceived === true) {
      receivedStatus = 'Clients who received the message.';
    } else if (request.isSendToReceived === false) {
      receivedStatus = 'Clients who did not receive the message.';
    }

    let readStatus = '';
    if (request.isSendToRead === true) {
      readStatus = 'Who read the message.';
    } else if (request.isSendToRead === false) {
      readStatus = 'Who did not read the message.';
    }

    let replyStatus = '';
    if (request.isSendToReplied === true) {
      replyStatus = 'Who replied to the message.';
    } else if (request.isSendToReplied === false) {
      replyStatus = 'Who did not reply to the message.';
    }

    return `${receivedStatus} ${readStatus} ${replyStatus}`;
  }

  getRatingLabel(rating: ClientRating) {
    return ClientRating[rating];
  }

  getPipelineLabel(pipelineid: number) {
    return stages.ALL_PIPELINES.find(f => f.id == pipelineid).name;
  }

  get isWhatsapp(): boolean {
    return this.selectedRequest?.messageTemplate?.messageTemplateType == MessageTemplateType.Whatsapp;
  }

  get isEmail(): boolean {
    return this.selectedRequest?.messageTemplate?.messageTemplateType == MessageTemplateType.Email;
  }
}