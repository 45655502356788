<div class="chat-container">
    @if (isShowTextIfNoConversations) {
    <div class="no-conversations-wrapper">
        <div class="no-conversations">{{(isGlobalChat) ? textIfNoConversationsGlobalChat :
            textIfNoConversations}}</div>
    </div>
    }

    @if (chatList && chatList.length > 0) {
    <chat-selector (onChangeChat)="onChangeChat($event)" [isGlobalChat]="isGlobalChat"
        [chatList]="chatList"></chat-selector>
    }
    <div class="chat-box">
        <div #targetHeader class="header">
            @if (activeChat) {
            <div class="header-content">
                <div class="name">
                    @if (!activeChat.isWhatsAppConversation) {
                    <img src='../../assets/sms.png' class="chat-type-icon" />
                    }
                    @if (activeChat.isWhatsAppConversation) {
                    <img src='../../assets/whatsapp.png' class="chat-type-icon" />
                    }

                    @if (activeChat?.dealId) {
                    <a style="text-decoration: none;" [href]='DealUtil.getDealUrl(activeChat?.dealId)'
                        target='_blank'>{{getActiveChatTitle()}}</a>
                    }
                    @if (!activeChat?.dealId) {
                    <span>
                        {{ getActiveChatTitle() }}</span>
                    }
                </div>
                @if (this.activeChat.isWhatsAppConversation) {
                <div class="session-status">
                    @if (isSessionActive()) {
                    <div class="session-active">
                        <span>Session expires after: </span>{{timer}}
                    </div>
                    }
                    @if (!isSessionActive()) {
                    <div class="session-inactive">
                        Session not active, please send template message
                    </div>
                    }
                </div>
                }
            </div>
            }
        </div>
        <div #targetMessagesList class="messages-list">
            @if (isSpinner) {
            <div class="spinner">
                <mat-spinner [diameter]="100"></mat-spinner>
            </div>
            }
            @if (!isSpinner) {

            @for (message of activeChat?.messages; track message) {
            <div>
                <div [ngClass]="getMessageClasses(message)">
                    @for (media of message.medias; track media) {
                    <div>
                        @if (FileHelper.isImage(media.contentType)) {
                        <img [src]="FileHelper.getBase64String(media)" (click)="openFullscreen($event)"
                            class="preview-image" />
                        }
                        @if (FileHelper.isVideo(media.contentType)) {
                        <video class="preview-video" controls>
                            <source [src]="FileHelper.getBase64String(media)" [type]="media.contentType" />
                        </video>
                        }
                        @if (FileHelper.isAudio(media.contentType)) {
                        <audio controls>
                            <source [src]="FileHelper.getBase64String(media)" [type]="media.contentType" />
                        </audio>
                        }
                    </div>
                    }
                    @if(TwilioHelper.isReactionMessage(message))
                    {
                    <pre class="text"><i>{{TwilioHelper.textForReactionMessage}}</i></pre>
                    }
                    @if(!TwilioHelper.isReactionMessage(message))
                    {
                    <pre class="text">{{message.text}}</pre>
                    }
                    <div>
                        <div [ngClass]="getTimeClasses(message)">
                            {{DateHelper.utcStringToLocalDate(message.time) | date :'dd-MMM-yyyy h:mm a'}}
                        </div>
                        @if (!message.isClientMessage) {
                        <div class="icon" bootstrapTooltip={{getErrorMessageTooltip(message)}}>
                            <fa-icon [icon]="TwilioHelper.getMessageIcon(message)"></fa-icon>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }

            }
            <div #targetScroll></div>
        </div>
        <div #targetMessagesInput class="message-input">
            @if (isAllowSendMessage) {

            @if (activeChat && activeChat.isWhatsAppConversation) {
            <button (click)="openInput()" [disabled]="isSpinner || isMessageSending" class="attach-button"><fa-icon
                    [icon]="TwilioHelper.faPaperclip"></fa-icon>Attach</button>
            }
            @if (pervUrl) {
            <div class="attached-file-info">
                <div class="file-preview">
                    <img [src]="pervUrl" class="preview-thumbnail" />
                    <button type="button" mdbBtn (click)="removeAttach()" class="close-btn waves-light btn-danger btn"
                        style="float: right;" aria-label="Close" mdbWavesEffect>
                        <i class="material-icons">close</i>
                    </button>
                </div>
                <div class="file-details">
                    <div>{{selectedFile.name}}</div>
                    <div>{{FileHelper.fileSizeToView(selectedFile.size)}}</div>
                </div>
            </div>
            }
            <textarea #targetTextarea placeholder="Type your message here..." maxlength="1600"
                [disabled]="isSpinner || isMessageSending" class="message-textarea" (paste)="autoGrow()"
                (keyup)="autoGrow()" [(ngModel)]="newMessageText"></textarea>
            <button (click)="sendMessage()" [disabled]="isSpinner || isMessageSending" class="send-button"
                [ngStyle]="{ 'justify-content': 'center', 'display': 'flex', 'align-items': 'center'}">
                @if (isMessageSending) {
                <div class="spinner" style="margin-right: 10px;">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
                } Send
            </button>
            <input #fileInput type="file" class="upload" (change)="changeListener($event.target.files)"
                [accept]="fileAccept" style="display: none">

            }
        </div>
    </div>
</div>

<div #fullscreenView class="fullscreen">
    <button class="fullscreen-close-btn waves-light btn-danger btn" style="float: right;" (click)="closeFullscreen()">
        <i class="material-icons">close</i>
    </button>
    <img #fullscreenImg src="" alt="image">
</div>