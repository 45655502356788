import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ClientRating } from '../../core/enums/ClientRating';
import { CustomSelectFilterWithoutLazyComponent } from '../../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import * as stages from '../../../assets/stages_new_prospespects';
import { ChipsSelectFilterDataModel } from 'src/app/core/models/CustomChipsSelectFilterModel';
import { User } from 'src/app/core/models/UserModel';
import { LocationChipsFilterComponent } from 'src/app/shared/filters/location-chips-filter.component';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { UsersService } from 'src/app/core/services/users.service';
import { Pipeline } from 'src/app/core/models/Pipeline';
import { CustomSelectFilterGroupedDataComponent } from 'src/app/shared/custom/custom-select-grouped-data/custom-select-grouped-data';
import { Stage } from '../../core/models/Stage';

@Component({
  selector: 'pd-bulk-message-filter',
  templateUrl: './bulk-message-filter.component.html',
  styleUrls: ['./bulk-message-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageFilterComponent implements OnInit {
  @ViewChild('locationFilter') public locationFilter: LocationChipsFilterComponent;
  @ViewChild('ownerFilter') public customSelectOwner: CustomSelectFilterWithoutLazyComponent;
  @ViewChild('pipelineFilter') public customSelectPipeline: CustomSelectFilterGroupedDataComponent;

  public ownersLabel = 'Clothiers';
  public LocationsLabel = 'Locations';
  public pipelineLabel = 'SelectAll()';

  public ratings = [
    { label: 'NA clients', value: ClientRating.NA, checked: false },
    { label: 'C clients', value: ClientRating.C, checked: false },
    { label: 'B clients', value: ClientRating.B, checked: false },
    { label: 'A clients', value: ClientRating.A, checked: false }
  ];

  public spinner = false;

  private allUsers: User[] = [];
  private filteredLocations: ChipsSelectFilterDataModel[] = [];
  private filteredOwners = new ReplaySubject<User[]>(1);
  private filteredPipeline = new ReplaySubject<Pipeline[]>(1);

  constructor(private cdRef: ChangeDetectorRef, private usersService: UsersService) { }

  public async ngOnInit() {
    this.spinner = true;
    this.cdRef.detectChanges();
    await this.loadUsers();
    this.setupFilters();
    this.spinner = false;
    this.cdRef.detectChanges();
  }

  private async loadUsers() {
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];
    const result = await this.usersService.getUsersByRoles();
    this.allUsers = result.filter(user => roles.includes(user.role_id));
  }

  private setupFilters() {
    this.getOwnersFilterData();
    this.getPipelineFilterData();
    this.locationFilter.resetFilter();
  }

  public getLocations(data: ChipsSelectFilterDataModel[]) {
    this.filteredLocations = data;
  }

  public getOwnersFilterData() {
    this.filteredOwners.next([...this.allUsers]);
    this.customSelectOwner?.setData(this.allUsers);
  }

  public getOwnersAutocomplete(event) {
    const search = event.searchString?.toLowerCase() || '';
    const filteredOwners = this.allUsers.filter(user =>
      user.name.toLowerCase().includes(search)
    );
    this.filteredOwners.next(filteredOwners);
    this.customSelectOwner?.setData(filteredOwners);
  }

  public getPipelineFilterData() {
    const pipelines = stages.ALL_PIPELINES.filter(pipeline =>
      [stages.ClientsPipeline.id, stages.OpenOrders.id, stages.ClothierContactClientsPipeline.id].includes(pipeline.id)
    );
    this.filteredPipeline.next([...pipelines]);
    this.customSelectPipeline?.setData(pipelines);
    this.customSelectPipeline?.selectAllData();
  }

  public getPipelineAutocomplete(event) {
    const search = event.searchString?.toLowerCase() || '';
    const filteredPipelines = stages.ALL_PIPELINES.filter(pipeline =>
      pipeline.name.toLowerCase().includes(search)
    );
    this.filteredPipeline.next(filteredPipelines);
    this.customSelectPipeline?.setData(filteredPipelines);
  }

  public stagesToPipelinesArray(stagesToTransform: Stage[]) {
    return stagesToTransform.reduce((result, stage) => {
      const pipeline = stages.ALL_PIPELINES.find(p => p.id === stage.pipeline_id);
      if (pipeline) {
        const existing = result.find(r => r.name === pipeline.name);
        if (existing) {
          existing.values.push(stage);
        } else {
          result.push({ name: pipeline.name, values: [stage] });
        }
      }
      return result;
    }, []);
  }

  public validation() {
    return this.filteredLocations.length > 0 &&
      this.customSelectPipeline.dataArray.length > 0 &&
      !this.spinner;
  }

  public getFilterData() {
    return {
      clientRatings: this.ratings.filter(f => f.checked).map(r => r.value),
      pipelines: this.customSelectPipeline.dataArray.map(pipeline => pipeline.id),
      ownerIds: this.customSelectOwner.dataArray.map(owner => owner.id),
      locations: this.filteredLocations
    };
  }

  public trackByRating(index: number, rating: any): number {
    return rating.value;
  }
}