import { Component, OnInit, Input } from '@angular/core';
import { User } from './../../core/models/UserModel';
import { ThirdPartyLinksService } from './../../core/services/third-party-links.service';
import { UserContextService } from './../../core/services/user-context.service';
import { LinkTypes } from 'src/app/core/enums/LinkTypes';
import { ThirdPartyLink } from 'src/app/core/models/ThirdPartyLink';
import { ThirdPartyLinkOrder } from 'src/app/core/models/ThirdPartyLinkOrder';

@Component({
  selector: 'pd-third-party-links',
  templateUrl: './third-party-links.component.html',
  styleUrls: ['./third-party-links.component.scss']
})
export class ThirdPartyLinksComponent implements OnInit {
  @Input() dealId: number;
  @Input() isMyDeal: boolean = true;

  editedThirdPartyLink: ThirdPartyLink;
  thirdPartyLinks: Array<ThirdPartyLink> = [];
  maxLinksCount: number = 4;
  currentUser: User;
  thirdPartyLinkOrders: ThirdPartyLinkOrder[] = [];
  deletedThirdPartyLinks: Array<ThirdPartyLink> = [];

  thirdPartyLinkOrder = [1, 2, 3, 4];
  thirdPartyLinkTypes = [
    { value: LinkTypes.Instagram, name: this.getLinkTypeNames(LinkTypes.Instagram) },
    { value: LinkTypes.WhatsApp, name: this.getLinkTypeNames(LinkTypes.WhatsApp) },
    { value: LinkTypes.vCard, name: this.getLinkTypeNames(LinkTypes.vCard) },
    { value: LinkTypes.Website, name: this.getLinkTypeNames(LinkTypes.Website) }
  ];

  public isEdit = false;
  public prevLinks = [];

  constructor(private serv: ThirdPartyLinksService, private userContextService: UserContextService) { }

  async ngOnInit() {
    this.isEdit = false;
    this.currentUser = this.userContextService.user.value;
    await this.loadThirdPartyLinks();
    this.populateThirdPartyLinkOrders();
  }

  ngOnDestroy() {
    this.editedThirdPartyLink = null;
    this.thirdPartyLinks = [];
  }

  async loadThirdPartyLinks() {
    this.editedThirdPartyLink = null;
    this.thirdPartyLinks = await this.serv.getThirdPartyLinksByDealId(this.dealId);
    this.thirdPartyLinks = this.thirdPartyLinks.sort((a, b) => a.order - b.order);
    this.prevLinks = this.deepClone(this.thirdPartyLinks);
  }

  public onLinkChange(link, updatedLinkModel) {
    link.tempNewValue = updatedLinkModel.link;
  }

  async addThirdPartyLink() {
    this.isEdit = true;
    this.editedThirdPartyLink = new ThirdPartyLink();
    this.editedThirdPartyLink.id = -1;
    this.editedThirdPartyLink.dealId = this.dealId;
    this.editedThirdPartyLink.link = "";
    this.editedThirdPartyLink.linkType = LinkTypes.Instagram;
    this.editedThirdPartyLink.order = this.getNextAvailableOrder();
    this.thirdPartyLinks.push(this.editedThirdPartyLink);
  }

  async saveThirdPartyLinks() {
    this.thirdPartyLinks = this.thirdPartyLinks.filter(link => link.link && link.link.trim().length > 0);
    const idsToDelete = this.deletedThirdPartyLinks.map(link => link.id);
    const linksToUpdate = this.thirdPartyLinks.filter(link => this.deletedThirdPartyLinks.indexOf(link) === -1);

    await this.serv.deleteThirdPartyLinks(idsToDelete);
    await this.serv.updateThirdPartyLinks(linksToUpdate);
  
    this.deletedThirdPartyLinks = [];
    
    this.populateThirdPartyLinkOrders();
    await this.loadThirdPartyLinks();
    
    this.isEdit = false;
  }

  async deleteThirdPartyLink(thirdPartyLink: ThirdPartyLink) {
    const index = this.thirdPartyLinks.indexOf(thirdPartyLink);
    if (index !== -1) {
      this.thirdPartyLinks.splice(index, 1);
      this.deletedThirdPartyLinks.push(thirdPartyLink);
    }
  }
  
  public changeStatus(){
    this.isEdit = !this.isEdit;
  }

  cancelThirdPartyLink() {
    this.thirdPartyLinks = this.deepClone(this.prevLinks);
    this.deletedThirdPartyLinks = [];
    this.editedThirdPartyLink = null;
    this.isEdit = false;
  }

  getMaxOrder(): number {
    return Math.max(...this.thirdPartyLinks.map(link => link.order), 0);
  }

  getAvailableOrders(): number[] {
    const usedOrders = this.thirdPartyLinks.map(link => link.order);
    return this.thirdPartyLinkOrder.filter(order => !usedOrders.includes(order));
  }

  getNextAvailableOrder(): number {
    const availableOrders = this.getAvailableOrders();
    return availableOrders.length > 0 ? availableOrders[0] : this.getMaxOrder() + 1;
  }

  populateThirdPartyLinkOrders() {
    this.thirdPartyLinkOrders = [];
    this.thirdPartyLinks.forEach((link) => {
      this.thirdPartyLinkOrders.push({
        id: link.id,
        order: link.order
      });
    });
  }

  getLinkTypeNames(type: LinkTypes) {
    switch (type) {
      case LinkTypes.Instagram:
        return "Instagram";
      case LinkTypes.WhatsApp:
        return "WhatsApp";
      case LinkTypes.vCard:
        return "vCard";
      case LinkTypes.Website:
        return "Website";
      default:
        return "Unknown";
    }
  }

  private deepClone(array: Object[]) {
    const newArray: any = [];
    array.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }

  public onOrderSelectChange(link: ThirdPartyLink, newOrder: any) {
    const existingLink = this.thirdPartyLinks.find(l => l.order === newOrder && l.id !== link.id);

    if (existingLink) {
        existingLink.order = this.getNextAvailableOrder();
        link.order = newOrder;
    } else {
        link.order = newOrder;
    }
  }

  public get IsShowAddOneMore(): boolean {
    return this.thirdPartyLinks.length < this.maxLinksCount;
  }
}