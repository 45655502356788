import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClientRating } from 'src/app/core/enums/ClientRating';
import { EnumUtil } from 'src/app/core/utils/enum.util';
import { BulkMessageRequestApiService } from 'src/app/core/services/bulk-message/bulk-message-request-api.service';
import { RequestStatus } from 'src/app/core/enums/RequestStatus';
import { BulkMessageRequestReportViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestReportViewModel';
import { FilteredDealDto } from 'src/app/core/models/dto/FilteredDealDto';
import { BulkMessageJobModel } from 'src/app/core/models/task-models/BulkMessageJobModel';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { BulkMessageSettingApiService } from 'src/app/core/services/bulk-message/bulk-message-setting-api.service';
import { BulkMessageSettingViewModel } from 'src/app/core/models/bulk-message/BulkMessageSettingsViewModel';
import { BulkMessageRequestUpdateDto } from 'src/app/core/models/dto/BulkMessageRequestUpdateDto';
import { BulkMessageEmailComponent } from './bulk-message-email/bulk-message-email.component';
import { BulkMessageWhatsappComponent } from './bulk-message-whatsapp/bulk-message-whatsapp.component';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { MessageTemplateService } from 'src/app/core/services/message-template.service';
import { BulkMessageFacadeService } from 'src/app/core/services/bulk-message/bulk-message-facade.service';
import { Observable } from "rxjs";
import { MessageStatuses } from 'src/app/core/enums/MessageStatuses';
import { ActivatedRoute } from '@angular/router';
import { BulkMessageRequestViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestViewModel';

@Component({
  selector: 'pd-bulk-message-setting',
  templateUrl: './bulk-message-setting.component.html',
  styleUrls: ['./bulk-message-setting.component.scss']
})
export class BulkMessageSettingComponent implements OnInit {
  @ViewChild('emailRequest') public emailRequest: BulkMessageEmailComponent;
  @ViewChild('whatsappRequest') public whatsappRequest: BulkMessageWhatsappComponent;

  public isArchive = false;
  public settingId: number;
  public deals: FilteredDealDto[] = [];
  public reportRows: BulkMessageRequestReportViewModel[] = [];
  public filteredReportRows: BulkMessageRequestReportViewModel[] = [];
  public copiedTemplateInfo$: Observable<MessageTemplateViewModel>;
  public setting: BulkMessageSettingViewModel;
  public templates: MessageTemplateViewModel[] = [];
  public selectedRequest: BulkMessageRequestViewModel;

  public MessageStatuses = MessageStatuses;
  public MessageTemplateType = MessageTemplateType;

  public isEmailToSend = false;
  public isWhatsappToSend = false;
  public scheduledSendDate: Date;

  public spinner = false;

  public ratings = [
    { label: 'NA clients', count: 0, value: ClientRating.NA, checked: false },
    { label: 'C clients', count: 0, value: ClientRating.C, checked: false },
    { label: 'B clients', count: 0, value: ClientRating.B, checked: false },
    { label: 'A clients', count: 0, value: ClientRating.A, checked: false }
  ];

  private sendBulkMessage = 'Do you confirm bulk message using this setting?';
  private messageStatusFilterData: any;

  constructor(
    private bulkMessageFacadeService: BulkMessageFacadeService,
    private bulkMessageSettingService: BulkMessageSettingApiService,
    private bulkMessageRequestService: BulkMessageRequestApiService,
    private messageTemplateService: MessageTemplateService,
    private route: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.route.params.subscribe(async params => {
      this.settingId = +params['id'];
      await this.loadInitialData();
      this.spinner = false;
    });
  }

  private async loadInitialData(): Promise<void> {
    this.templates = await this.messageTemplateService.getTemplates();
    this.setting = await this.bulkMessageSettingService.getById(this.settingId);
    this.isArchive = this.setting.isArchived;
    this.copiedTemplateInfo$ = this.bulkMessageFacadeService.getCopiedTemplateSetting();
  }

  public get IsNewRequest(): boolean {
    return !this.selectedRequest || this.selectedRequest.statusId === RequestStatus.New;
  }

  public async onConfigureMessage(event): Promise<void> {
    this.isWhatsappToSend = event.isWhatsApp;
    this.isEmailToSend = event.isEmail;
  }

  private async createTemplate(templateData): Promise<number> {
    const data = new MessageTemplateViewModel({
      from: templateData.from,
      fromName: templateData.fromName,
      emailsCc: templateData.emailsCc,
      emailsBcc: templateData.emailsBcc,
      subject: templateData.subject,
      body: templateData.body,
      messageTemplateType: MessageTemplateType.Email,
      variableDescriptions: templateData.variableDescriptions,
      isNeedUnsubscribeHtml: templateData.isNeedUnsubscribeHtml
    });
    return await this.messageTemplateService.addTemplate(data);
  }

  private async addRequest(templateData): Promise<number> {
    const data = new BulkMessageRequestUpdateDto({
      parentRequestId: this.IsNewRequest ? null : this.selectedRequest.id,
      settingId: this.setting.id,
      templateId: templateData.messageTemplateId,
      variables: templateData.variables,
      replyRecipientIds: templateData.replyRecipientIds,
      expirationDate: templateData.expirationDate,
      scheduledSendDate: this.scheduledSendDate,
      ...this.getFilterData()
    });
    return await this.bulkMessageRequestService.add(data);
  }

  private getFilterData() {
    if (this.messageStatusFilterData) {
      const { sendToReceived, sendToRead, sendToReplied } = this.messageStatusFilterData;
      return {
        isSendToReceived: sendToReceived,
        isSendToRead: sendToRead,
        isSendToReplied: sendToReplied
      };
    }
    return {};
  }

  public async onSendEmail(): Promise<void> {
    if (!confirm(this.sendBulkMessage)) return;

    this.spinner = true;
    const templateData = this.emailRequest.getData();
    templateData.messageTemplateId = await this.createTemplate(templateData);
    await this.processSendRequest(templateData);
    this.spinner = false;
  }

  public async onSendWhatsapp(): Promise<void> {
    if (!confirm(this.sendBulkMessage)) return;

    this.spinner = true;
    const templateData = this.whatsappRequest.getData();
    await this.processSendRequest(templateData);
    this.spinner = false;
  }

  private async processSendRequest(templateData) {
    const requestId = await this.addRequest(templateData);
    const dealIds = this.IsNewRequest ? this.deals.map(d => d.dealId) : this.reportRows.map(r => r.dealId);
    const data = new BulkMessageJobModel({ requestId, dealIds });
    await this.bulkMessageRequestService.sendBulkMessage(data);
  }

  private async loadData(): Promise<void> {
    const { clientRatings, pipelines, locations, owners } = this.setting;
    this.deals = await this.bulkMessageSettingService.getDealsBySetting({ clientRatings, pipelines, locations, ownerIds: owners.map(o => o.id) });
  }

  private async loadReportData(): Promise<void> {
    this.reportRows = await this.bulkMessageRequestService.getRequestReport(this.selectedRequest.id);
    this.onFilterDealsByMessageStatus(this.messageStatusFilterData);
  }

  public onRequestSelectChange(request: BulkMessageRequestViewModel): void {
    this.selectedRequest = request;
    this.IsNewRequest ? this.loadData() : this.loadReportData();
  }

  public onFilterDealsByMessageStatus(filters): void {
    this.messageStatusFilterData = filters;
    this.filteredReportRows = filters ? this.filterDealsByStatus(filters) : this.reportRows;
  }

  private filterDealsByStatus(filters) {
    return this.reportRows.filter(message => {
      const isReceived = message.isSuccessful;
      const isRead = [MessageStatuses.Read, MessageStatuses.Unsubscribed, MessageStatuses.Answered].includes(message.status);
      const isAnswered = message.status === MessageStatuses.Answered;

      return (
        this.matchesFilter(filters.sendToReceived, isReceived) &&
        this.matchesFilter(filters.sendToRead, isRead) &&
        this.matchesFilter(filters.sendToReplied, isAnswered)
      );
    });
  }

  private matchesFilter(filterValue, status) {
    return filterValue !== null ? filterValue === status : true;
  }

  public getClientRatingName(clientRating: ClientRating): string {
    return ClientRating[clientRating];
  }

  public getPlaceholdersCount(text: string): number {
    return (text.match(/{{\d+}}/g) || []).length;
  }

  public validation(): boolean {
    return !this.isEmailToSend && !this.spinner;
  }

  public getWhatsappTemplates() {
    return this.templates.filter(f => f.messageTemplateType === MessageTemplateType.Whatsapp);
  }

  public onPaste() {
    this.emailRequest.setData(this.bulkMessageFacadeService.getCopiedTemplateSettingSnapshot());
    this.bulkMessageFacadeService.setCopiedTemplateSetting(null);
  }

  public trackByDealId(index: number, deal: FilteredDealDto): number {
    return deal.dealId;
  }
}