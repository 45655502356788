import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TemplateVariableContentTypes } from 'src/app/core/enums/TemplateVariableContentTypes';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { User } from 'src/app/core/models/UserModel';
import { UsersService } from 'src/app/core/services/users.service';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pd-bulk-message-whatsapp',
  templateUrl: './bulk-message-whatsapp.component.html',
  styleUrls: ['./bulk-message-whatsapp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageWhatsappComponent implements OnInit {
  @ViewChild('userFilter') public customSelectUser;
  @Input() public currentTemplate: MessageTemplateViewModel;
  @Input() public templates: MessageTemplateViewModel[];

  public TemplateVariableContentTypes = TemplateVariableContentTypes;

  public usersLabel = 'Clothiers';
  public spinner = false;

  private allUsers: User[] = [];
  private filteredUserMultiSelect = new ReplaySubject<User[]>(1);
  private filteredUsers: User[] = [];
  private expirationDate: Date;

  constructor(private usersService: UsersService) { }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.allUsers = await this.loadUsers();
    this.filteredUsers = this.allUsers;
    this.onLoadUsersFilterData();
    this.spinner = false;
  }

  private async loadUsers(): Promise<User[]> {
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];
    const result = await this.usersService.getUsersByRoles();
    return result.filter((x) => roles.includes(x.role_id));
  }

  public onLoadUsersFilterData(): void {
    this.filteredUsers = [...this.allUsers];
    this.filteredUserMultiSelect.next(this.allUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
    }
  }

  public onUsersAutocomplete(event: any): void {
    const search = event.searchString?.toLowerCase() || '';
    const filtered = this.allUsers.filter((loc) => loc.name.toLowerCase().includes(search));
    this.filteredUserMultiSelect.next(filtered);
    this.filteredUsers = filtered;
    this.customSelectUser.setData(filtered);
  }

  public onExpirationDateChange(value: Date): void {
    this.expirationDate = value;
  }

  public onTemplateSelectionChange(event: any): void {
    this.currentTemplate = this.templates.find((f) => f.id === event.value);
  }

  public isStaticVariable(variable: any): boolean {
    return variable.templateVariableContentType === TemplateVariableContentTypes['Static Text'];
  }

  public getData(): any {
    const variables = this.currentTemplate.variableDescriptions.map((currentTemplateVariable) => ({
      variableDescriptionId: currentTemplateVariable.id,
      staticValue: currentTemplateVariable.exampleData
    }));
    return {
      messageTemplateId: this.currentTemplate.id,
      variables,
      replyRecipientIds: this.customSelectUser?.dataArray.map((u) => u.id),
      expirationDate: this.expirationDate
    };
  }

  public validation(): boolean {
    return true;
  }

  public trackByVariable(index: number, variable: any): number {
    return variable.id;
  }
}