import { Component, Input, Output, EventEmitter, ViewContainerRef, OnInit } from '@angular/core';
import { AlertTypes } from 'src/app/core/enums/AlertTypes';
import { IconDefinition,  faCheck, faClose, faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'pd-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  @Input() public type: AlertTypes;
  @Input() public message: string;
  @Input() public buttonText: string;
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  public icon: IconDefinition;
  public AlertTypes = AlertTypes;

  constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    switch(this.type) {
      case AlertTypes.Success: this.icon = faCheck; break;
      case AlertTypes.Error: this.icon = faClose; break;
      case AlertTypes.Warning: this.icon = faExclamation; break;
    }
  }

  public closeAlert() {
    this.onClose.emit();
  }
}
