<div class="row">
    <div class="col-md-6 col-xl-2 filter__item assign-clients">
      <div class="row">
        <div class="col filter__label">Assign clients by rating</div>
      </div>
      <div class="row adjust-checkbox">
        <div class="col-12 checkbox" *ngFor="let rating of ratings; trackBy: trackByRating">
          <mat-checkbox [(ngModel)]="rating.checked">{{ rating.label }}</mat-checkbox>
        </div>
      </div>
    </div>
  
    <div class="col-md-6 col-xl-2 filter__item">
      <div class="row">
        <div class="col setting__label">Choose Pipeline</div>
      </div>
      <div class="row">
        <div class="col">
          <pd-custom-select-without-lazy
            #pipelineFilter
            (loadDataEvent)="getPipelineFilterData()"
            [displaySearch]="false"
            (autoCompleteEvent)="getPipelineAutocomplete($event)"
            [dataLabel]="pipelineLabel"
            [startLabel]="pipelineLabel"
            [isMultiple]="true"
          ></pd-custom-select-without-lazy>
        </div>
      </div>
    </div>
  
    <div class="col-md-6 col-xl-2 filter__item">
      <div class="row">
        <div class="col setting__label">Choose Location</div>
      </div>
      <div class="row">
        <div class="col">
          <pd-location-chips-filter
            #locationFilter
            (filterDataChanged)="getLocations($event)"
            [isAllSelectedDefaultMode]="false"
          ></pd-location-chips-filter>
        </div>
      </div>
    </div>
  
    <div class="col-md-6 col-xl-2 filter__item">
      <div class="row">
        <div class="col setting__label">Choose Clothiers</div>
      </div>
      <div class="row">
        <div class="col">
          <pd-custom-select-without-lazy
            #ownerFilter
            (loadDataEvent)="getOwnersFilterData()"
            (autoCompleteEvent)="getOwnersAutocomplete($event)"
            [dataLabel]="ownersLabel"
            [startLabel]="ownersLabel"
            [isMultiple]="true"
          ></pd-custom-select-without-lazy>
        </div>
      </div>
    </div>
  </div>