"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function assign(target) {
  var sources = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    sources[_i - 1] = arguments[_i];
  }
  if (target == null) {
    throw new TypeError('Cannot convert undefined or null to object');
  }
  var to = Object(target);
  for (var index = 0; index < sources.length; index++) {
    var nextSource = sources[index];
    if (nextSource != null) {
      for (var nextKey in nextSource) {
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          to[nextKey] = nextSource[nextKey];
        }
      }
    }
  }
  return to;
}
exports.assign = assign;