import { TemplateVariableContentTypes } from "../../enums/TemplateVariableContentTypes";
import { TemplateVariableTypes } from "../../enums/TemplateVariableTypes";

export class MessageTemplateVariableDescriptionViewModel {
    public id: number; 
    public messageTemplateId: number; 
    public variableNumber: number;
    public templateVariableType: TemplateVariableTypes;
    public templateVariableContentType: TemplateVariableContentTypes;
    public exampleData: string;
}
