<div class="wrapper">
    <ng-container *ngIf="setting">
        <ng-container *ngIf="!spinner">
            <div class="col">
                <pd-bulk-message-table [title]="'Selected Setting'" [settings]="[setting]"
                    [isArchive]="setting?.isArchived" [isNoFilter]="true"
                    (onConfigureMessage)="onConfigureMessage($event)" (onSelectRequest)="onRequestSelectChange($event)"
                    [isSelectedItem]="true"></pd-bulk-message-table>
            </div>

            <ng-container *ngIf="isEmailToSend">
                <div class="settings__container">
                    <ng-container *ngIf="copiedTemplateInfo$ | async">
                        <div class="row row-justify-end">
                            <button class="btn btn-success waves-light" style="max-width: 200px;" (click)="onPaste()">
                                Paste Template
                            </button>
                        </div>
                    </ng-container>
                    <pd-bulk-message-email #emailRequest></pd-bulk-message-email>

                    <div class="row row-space-between">
                        <div class="col" *ngIf="!IsNewRequest">
                            <pd-bulk-message-status-filter (onFilterChange)="onFilterDealsByMessageStatus($event)"
                                [messageTemplateType]="MessageTemplateType.Email"></pd-bulk-message-status-filter>
                        </div>
                    </div>

                    <div class="row row-justify-end">
                        <button class="btn btn-success waves-light" style="max-width: 200px;"
                            (click)="onSendEmail($event)" [disabled]="!emailRequest.validation()">
                            Send Emails
                        </button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="isWhatsappToSend">
                <div class="settings__container">
                    <pd-bulk-message-whatsapp #whatsappRequest [templates]="getWhatsappTemplates()"
                        [currentTemplate]="templates[0]"></pd-bulk-message-whatsapp>

                    <div class="row row-space-between">
                        <div class="col" *ngIf="!IsNewRequest">
                            <pd-bulk-message-status-filter (onFilterChange)="onFilterDealsByMessageStatus($event)"
                                [messageTemplateType]="MessageTemplateType.Whatsapp"></pd-bulk-message-status-filter>
                        </div>
                    </div>

                    <div class="row row-justify-end">
                        <button class="btn btn-success waves-light" style="max-width: 200px;"
                            (click)="onSendWhatsapp($event)" [disabled]="!whatsappRequest.validation()">
                            Send Messages
                        </button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedRequest">
                <ng-container *ngIf="IsNewRequest">
                    <div class="col flex-center">
                        <div [ngClass]="{ 'visible': !spinner, 'hidden': spinner }">
                            <div class="requests-table">
                                <div class="requests-table-label">Clients ({{ deals.length }})</div>
                                <div class="requests-table-header row">
                                    <div class="col cell">Full Name</div>
                                    <div class="col cell">Client Rating</div>
                                    <div class="col cell">Status</div>
                                    <div class="col cell">Location</div>
                                    <div class="col cell">Owner</div>
                                </div>
                            </div>
                            <div class="content-wrapper">
                                <div class="content">
                                    <div class="requests-table">
                                        <div class="requests-table-content row"
                                            *ngFor="let deal of deals; trackBy: trackByDealId">
                                            <div class="col cell">
                                                <a [href]="'/deals/' + deal.dealId" target="_blank">{{ deal.title }}</a>
                                            </div>
                                            <div class="col cell">{{ deal.clientRating }}</div>
                                            <div class="col cell">{{ deal.stageName }}</div>
                                            <div class="col cell">{{ deal.location }}</div>
                                            <div class="col cell">{{ deal.userName }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="spinner">
                            <div class="spinner">
                                <mat-spinner></mat-spinner>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="!IsNewRequest">
                    <div class="col flex-center">
                        <div [ngClass]="{ 'visible': !spinner, 'hidden': spinner }">
                            <div class="requests-table">
                                <div class="requests-table-label">
                                    WhatsApp/Email Bulk Message Report ({{ filteredReportRows?.length }})
                                </div>
                                <div class="requests-table-header row">
                                    <div class="col cell">Full Name</div>
                                    <div class="col cell">Client Rating</div>
                                    <div class="col cell">Status</div>
                                    <div class="col cell">Location</div>
                                    <div class="col cell">Owner</div>
                                    <div class="col cell">Message Sending Info</div>
                                    <div class="col cell">Message Status</div>
                                    <div class="col cell">Is Successful</div>
                                </div>
                            </div>
                            <div class="content-wrapper">
                                <div class="content">
                                    <div class="requests-table-content row"
                                        *ngFor="let reportRow of filteredReportRows; trackBy: trackByDealId">
                                        <div class="col cell">
                                            <a [href]="'/deals/' + reportRow.dealId" target="_blank">{{ reportRow.title
                                                }}</a>
                                        </div>
                                        <div class="col cell">{{ getClientRatingName(reportRow.clientRating) }}</div>
                                        <div class="col cell">{{ reportRow.stageName }}</div>
                                        <div class="col cell">{{ reportRow.location }}</div>
                                        <div class="col cell">{{ reportRow.ownerName }}</div>
                                        <div class="col cell">{{ reportRow.messageStatusInfo }}</div>
                                        <div class="col cell">{{ MessageStatuses[reportRow.status] }}</div>
                                        <div class="col cell">{{ reportRow.isSuccessful ? 'Yes' : 'No' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="spinner">
                            <div class="spinner">
                                <mat-spinner></mat-spinner>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="spinner">
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
    </ng-container>
</div>