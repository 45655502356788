<ul>
  <ng-container *ngFor="let node of treeData">
    <li>
      <div [ngClass]="getStatusClass(node.statusId)">
        <div 
          class="request-container"
          [ngClass]="{'border-selected': node.id == selectedRequest.id, 'border-unselected': node.id != selectedRequest.id}"
          (click)="requestSelectChange(node)">
          {{ node.messageTemplate ? MessageTemplateType[node.messageTemplate.messageTemplateType] : 'Unknown' }} -
          {{ EnumUtil.getBulkRequestStatusViewLabel(node.statusId) }}
          
          {{ (node.statusId === RequestStatus.Sheduled ? node.scheduledSendDate : node.created) | date: 'dd-MMM-yyyy h:mm a' }}
        </div>
      </div>
      <ul *ngIf="node.children && node.children.length > 0">
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
      </ul>
    </li>
  </ng-container>
</ul>

<ng-template #recursiveList let-nodes>
  <ul>
    <ng-container *ngFor="let node of nodes">
      <li>
        <div [ngClass]="getStatusClass(node.statusId)">
          <div 
            class="request-container"
            [ngClass]="{'border-selected': node.id == selectedRequest.id, 'border-unselected': node.id != selectedRequest.id}"
            (click)="requestSelectChange(node)">
            {{ node.messageTemplate ? MessageTemplateType[node.messageTemplate.messageTemplateType] : 'Unknown' }} -
            {{ EnumUtil.getBulkRequestStatusViewLabel(node.statusId) }}
            
            {{ (node.statusId === RequestStatus.Sheduled ? node.scheduledSendDate : node.created) | date: 'dd-MMM-yyyy h:mm a' }}
          </div>
        </div>
        <ul *ngIf="node.children && node.children.length > 0">
          <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</ng-template>