import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from 'src/app/core/models/UserModel';
import { RequestStatus } from 'src/app/core/enums/RequestStatus';
import { MessageTemplateViewModel } from 'src/app/core/models/message-template/MessageTemplateViewModel';
import { BulkMessageRequestMessageTemplateVariableViewModel } from 'src/app/core/models/bulk-message/BulkMessageRequestMessageTemplateVariableViewModel';
import { MessageTemplateType } from 'src/app/core/enums/MessageTemplateType';
import { EnumUtil } from 'src/app/core/utils/enum.util';

interface RequestNode {
  id: number;
  parentRequestId: number | null;
  statusId: RequestStatus;
  messageTemplate: MessageTemplateViewModel;
  variables: BulkMessageRequestMessageTemplateVariableViewModel[];
  replyRecipients?: User[];
  expirationDate?: Date;
  scheduledSendDate?: Date;
  created: Date;
  isSelected: boolean;
  children?: RequestNode[];
}

@Component({
  selector: 'pd-bulk-message-request-tree',
  templateUrl: './bulk-message-request-tree.component.html',
  styleUrls: ['./bulk-message-request-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessageRequestTreeComponent implements OnInit {
  @Input() requests: RequestNode[];
  @Output() public onRequestSelectChange = new EventEmitter<number>();

  treeData: RequestNode[] = [];
  selectedRequest: RequestNode;

  MessageTemplateType = MessageTemplateType;
  EnumUtil = EnumUtil;
  RequestStatus = RequestStatus;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.requestSelectChange(this.requests[0]);
    this.treeData = this.buildTree(this.requests, null);
    this.cdRef.detectChanges();
  }

  getStatusClass(statusId: RequestStatus): string {
    const statusClasses = {
      [RequestStatus.Failed]: 'status-failed',
      [RequestStatus.Queued]: 'status-queued',
      [RequestStatus.Sheduled]: 'status-sheduled',
      [RequestStatus.InProgress]: 'status-inprogress',
      [RequestStatus.Completed]: 'status-completed'
    };
    return statusClasses[statusId] || '';
  }

  requestSelectChange(node: RequestNode): void {
    this.selectedRequest = node;
    this.onRequestSelectChange.emit(node.id);
    this.cdRef.detectChanges();
  }

  private buildTree(nodes: RequestNode[], parentId: number | null): RequestNode[] {
    return nodes
      .filter(node => node.parentRequestId === parentId)
      .map(node => ({
        ...node,
        children: this.buildTree(nodes, node.id)
      }));
  }
}