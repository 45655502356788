<div class="header">
    <h2>WhatsApp/Email Bulk Messages</h2>
</div>

<div class="wrapper">
    <ng-container>
        <div class="settings__container">
            <pd-bulk-message-filter #filter></pd-bulk-message-filter>
            <div class="row add-btn__container">
                <button class="btn btn-success waves-light add-btn" [disabled]="!(filter.validation())"
                    (click)="onAdd()">Add</button>
            </div>
        </div>

        <ul class="nav nav-tabs justify-content-left">
            <li class="nav-item" *ngFor="let tab of tabs; trackBy: trackByFn">
                <a class="nav-link" [ngClass]="{ 'active': tab.isActive }" (click)="onTabClick(tab)">
                    {{ tab.name }}
                </a>
            </li>
        </ul>

        <ng-container>
            <div *ngIf="tabs[0].isActive">
                <pd-bulk-message-table [isMain]="true" title="Bulk Message Rules" [settings]="getNonArchivedSettings()"
                    (onRemove)="onRemove($event)" (onArchive)="onArchive($event)">
                </pd-bulk-message-table>
            </div>
            <div *ngIf="tabs[1].isActive">
                <pd-bulk-message-table title="Archive" [isArchive]="true" [settings]="getArchivedSettings()"
                    (onArchive)="onArchive($event)">
                </pd-bulk-message-table>
            </div>
        </ng-container>

        <div *ngIf="spinner" class="spinner">
            <mat-spinner></mat-spinner>
        </div>
    </ng-container>
</div>